 /* jshint esversion: 6 */ 
 
import Vue from 'vue'
import App from './App.vue'
import router from "./routes"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';//样式文件一定要引入
import 'echarts-wordcloud';

Vue.config.productionTip = false
Vue.use(ElementUI);


new Vue({
  render: h => h(App),
  router
}).$mount('#app')

var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量

(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?a37d16e0748c508497c53aa5d095b091";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();