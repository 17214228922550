<template>
  <div  >
      <el-container >
      <el-header class="header">

        <ul class="ul-link">
          <li style="list-style:none;width:120px;margin-right:40px">
            <a href="/" class="brand">
              <img src="../../src/dathonicon/blackicon.png" style="height:26px;width:120px;"/>
            </a>
          </li>
          <li style="list-style:none;">
            <a href="https://mp.weixin.qq.com/s/t6HjNyGVKsG8kU77r4r6Sg" class="header-a" target="_blank"> 
            <el-badge value="hot" class="item">
                课程
            </el-badge>
            </a>
          </li>
          <li style="list-style:none;">
            <a href="/#/vip" class="header-a">会员</a>
          </li>
          <li style="list-style:none;">
            <div href="/" class="header-a" style="">
            <el-dropdown>
              <span class="el-dropdown-link" style="color:#18222a !important">
                题库<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button type="text" @click="gosql">SQL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
                  </el-dropdown-item>
                <el-dropdown-item>Python（尚未完成）</el-dropdown-item>
                <el-dropdown-item>统计学（尚未完成）</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            </div>
          </li>
          <li style="list-style:none;">
            <a href="/#/team" class="header-a">团队</a>
          </li>
          <li style="list-style:none;">
            <a href="/#/dashboard" class="header-a">行情</a>
          </li>
        </ul>

        <span style="flex:1"></span>

        <div class="header-content" v-if="token===null">
          <el-link  type="primary" class='zhuce' href="#/login">微信登录</el-link>
          <el-link  type="primary" class='denglu' href="#/zhlogin">账号登录</el-link>
          <!-- <a class='zhuce' >注册</a>
          <a href="#/login" class='denglu'>登录</a> -->
        </div>
        <div v-else class="header-content">
          <el-dropdown @command="onLogout" class="touxiang">
            <span class="el-dropdown-link">
              <el-avatar :src=this.touxiang></el-avatar>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >{{username}}</el-dropdown-item>
              <el-dropdown-item command=1>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>


          <el-dropdown @command="onLogout" class="vip" v-if="restime>0">
            <span class="el-dropdown-link" >
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='xiaoni'"></i>
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='139746406@qq.com'"></i>
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='644721169@qq.com'"></i>
                  <i class="iconfont icon-vip" style="color:red;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >您Vip将在{{restime}}天后过期</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>
      </el-header>

        <el-main class='main'>
            <el-row :gutter="20">
                <el-col :span="24">
                  <div style="height:60px;background-color:#63b2ee;padding: 5px;display: flex;align-items: center;justify-content: space-between;">
                      <img src="../assets/picture/authorewm.png" style="width: 60px; height: 60px;margin-right: 3px;">
                      <div style="flex-grow: 1;text-align: center;">
                          <span style="font-weight: bold; color: white; font-size: 36px;">数据分析师行情大盘</span>
                      </div>
                  </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                        <div id="counts-trend" style="height:400px;"></div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                        <div id="city-counts-trend" style="height:400px;"></div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                        <div id="city-counts-bar" style="height:400px;"></div>
                </el-col>
                <el-col :span="12">
                        <div id="city-salary-bar" style="height:400px;"></div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                        <div id="bussiness-counts-bar" style="height:600px;"></div>
                </el-col>
                <el-col :span="12">
                        <div id="bussiness-salary-bar" style="height:600px;"></div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                        <div id="education-counts-pie" style="height:400px;"></div>
                </el-col>
                <el-col :span="12">
                        <div id="education-salary-bar" style="height:400px;"></div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                        <div id="workyear-counts-pie" style="height:400px;"></div>
                </el-col>
                <el-col :span="12">
                        <div id="workyear-salary-bar" style="height:400px;"></div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                        <div id="cs-counts-bar" style="height:400px;"></div>
                </el-col>
                <el-col :span="12">
                        <div id="skill-wordcloud" style="height:400px;"></div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="8">
                        <div id="useless-counts-bar" style="height:500px;"></div>
                </el-col>
                <el-col :span="16">
                    <div>
                      <div class="table-header">
                        <h3 class="table-title">&nbsp;各城市常年挂着的岗位数据明细</h3>
                        <el-input
                          v-model="search"
                          placeholder="请输入内容"
                          style="width: 300px;margin:5px;"
                          clearable
                          @clear="handleFilter"
                          @keyup.enter.native="handleFilter"
                        >
                          <el-button slot="append" icon="el-icon-search" @click="handleFilter"></el-button>
                        </el-input>
                      </div>

                      <el-table
                        :data="pagedData"
                        max-height="408"
                        style="width: 100%;"
                        class="table-backgroud"
                        stripe
                      >
                        <el-table-column
                          prop="city_name"
                          label="城市"
                        ></el-table-column>
                        <el-table-column
                          prop="company_name"
                          label="公司名称"
                        ></el-table-column>
                        <el-table-column
                          prop="job_name"
                          label="岗位名称"
                        ></el-table-column>
                        <el-table-column
                          prop="salary"
                          label="薪资"
                        ></el-table-column>
                        <el-table-column
                          prop="first_time"
                          label="岗位首次记录时间"
                        ></el-table-column>
                        <el-table-column
                          prop="last_time"
                          label="岗位最新记录时间"
                        ></el-table-column>
                        <el-table-column
                          prop="time_diff"
                          label="时间差"
                        ></el-table-column>
                      </el-table>

                      <el-pagination
                        background
                        layout="prev, pager, next" 
                        :total="filteredData.length"
                        :page-size="pageSize"
                        @current-change="handlePageChange"
                        style="margin-top:5px;background-color:#f8f8f8;"
                      ></el-pagination>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                        <div  style="height:150px;background-color:#f8f8f8;padding: 10px;">
                          <h4>统计口径说明：</h4>
                          <span>* 数据源来自个人开发的定时爬虫程序，每周或每两周更新一次数据。<br></span>
                          <span>* 以上数据均做了岗位去重，去重粒度：岗位名+公司名+薪资+hr名。<br></span>
                          <span>* 活跃岗位定义 ：hr一周内有回复应聘者的。<br></span>
                          <span>* 常年挂着的岗位定义 ：该岗位 的最新记录时间 - 首次记录时间 > 60。意味着该岗位60天都还没招到人。<br></span>
                          <span>* 微信 ：xiaoni12345666 。交流/领资料/学数据 欢迎加我 :)<br></span>
                        </div>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
  </div>

</template>

<script>

import axios from "axios";
import * as echarts from 'echarts';
import 'echarts-wordcloud';

export default {
  name:"",
  created(){
    this.token = localStorage.getItem("Authorization")
    this.username = localStorage.getItem('username')
    this.getuselessdetaildata();
  },
  data(){
    return{
      token:"",
      username:"",
      touxiang:"",
      restime:"",
    //   图表
      data_jobtrend:"",
      data_cityinfo:"",
      data_bussiness:"",
      data_educationinfo:"",
      data_workyear:"",
      data_companysize:"",
      data_skillword:"",
      data_uselesscount:"",
      data_citytrend:"",

      search: '',
      page: 1,
      pageSize: 10,
      tableData: [],
    }
  },
  computed: {
    filteredData() {
      return this.tableData.filter(item => {
        return item.city_name.includes(this.search) 
          || item.company_name.includes(this.search)
          || item.job_name.includes(this.search);
      })
    },
    pagedData() {
      const start = (this.page - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredData.slice(start, end);
    }
  },
  methods:{
    onLogout(command){

      if (command==1){
        localStorage.removeItem("Authorization")
        localStorage.removeItem("headimg")
        localStorage.removeItem("username")
        localStorage.removeItem("dalay")
        localStorage.removeItem("_time")
        localStorage.removeItem("nickname")
        // remove所有存储
        this.$router.replace("/login")
      }
    },
    getusermessage(){
      var username = {"username":localStorage.getItem('username')}

      axios.post("https://dathonlearn.cn/users/getusermessage/",
      {
        headers:{
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:username}
      ).then(res=>{

        this.touxiang = res.data.data.touxiang
        this.restime = res.data.data.restime

        // 与评论共享数据
        localStorage.setItem("nickname",res.data.data.nickname)
        localStorage.setItem("headimg",res.data.data.touxiang)
        localStorage.setItem("restime",res.data.data.restime)
      })
    },
    gosql(){
      this.$router.push("/sql/exe18")
    },
    // 图表制作
    handleFilter() {
      this.search = '';
    },
    handlePageChange(page) {
      this.page = page;
    },
    getjobtrenddata(){
        axios.get('https://dathonlearn.cn/picdata/jobtrend/')
            .then(response => {
                this.data_jobtrend = response.data.data;
                this.jobtrendchart();
            })
            .catch(error => {
                console.log(error);
            });
    },
    getcitytrenddata(){
        axios.get('https://dathonlearn.cn/picdata/citytrend/')
            .then(response => {
                this.data_citytrend = response.data.data;
                this.citytrendchart();
            })
            .catch(error => {
                console.log(error);
            });
    },
    getcityinfodata(){
        axios.get('https://dathonlearn.cn/picdata/cityinfo/')
            .then(response => {
                this.data_cityinfo = response.data.data;
                this.citycountsbarchart();
                this.citysalarybarchart();
            })
            .catch(error => {
                console.log(error);
            });
    },
    getbussinessinfodata(){
        axios.get('https://dathonlearn.cn/picdata/bussinessinfo/')
            .then(response => {
                this.data_bussiness = response.data.data;
                this.bussinesscountssbarchart();
                this.bussinesssalarysbarchart();
            })
            .catch(error => {
                console.log(error);
            });
    },
    geteducationinfodata(){
        axios.get('https://dathonlearn.cn/picdata/educationinfo/')
            .then(response => {
                this.data_educationinfo = response.data.data;
                this.educationcountspiechart();
                this.educationsalarybarchart();
            })
            .catch(error => {
                console.log(error);
            });
    },
    getworkyearinfodata(){
        axios.get('https://dathonlearn.cn/picdata/workyearinfo/')
            .then(response => {
                this.data_workyear = response.data.data;
                this.workyearcountspiechart();
                this.workyearsalarybarchart();
            })
            .catch(error => {
                console.log(error);
            });
    },
    getcompanysizedata(){
        axios.get('https://dathonlearn.cn/picdata/companysize/')
            .then(response => {
                this.data_companysize = response.data.data;
                this.cscountsbarchart();
            })
            .catch(error => {
                console.log(error);
            });
    },
    getskillwordclouddata(){
        axios.get('https://dathonlearn.cn/picdata/skillwordcloud/')
            .then(response => {
                this.data_skillword = response.data.data;
                this.skillwordcloudchart();
            })
            .catch(error => {
                console.log(error);
            });
    },
    getuselesscountdata(){
        axios.get('https://dathonlearn.cn/picdata/uselesscount/')
            .then(response => {
                this.data_uselesscount = response.data.data;
                this.uselesscountsbarchart();
            })
            .catch(error => {
                console.log(error);
            });
    },
    getuselessdetaildata(){
        axios.get('https://dathonlearn.cn/picdata/uselessdetail/')
            .then(response => {
                console.log('明细数据',response.data.data);
                this.tableData = response.data.data;
            })
            .catch(error => {
                console.log(error);
            });
    },
    // 图表配置
    jobtrendchart () {
      var myChart = window.echarts.init(document.getElementById('counts-trend'));
      var option = {
            backgroundColor: '#F8F8F8',
            label:{show:true,position: 'left'},
            title: {
                text: '活跃岗位数趋势图'
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: this.data_jobtrend.series.map(item => item.name)
            },
            grid: {
                left: '3%',
                right: '3%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                // boundaryGap: false,
                data: this.data_jobtrend.xdata
            },
            yAxis: {
                type: 'value'
            },
            series: this.data_jobtrend.series
            };
      myChart.setOption(option);
    },
    citytrendchart () {
      var myChart = window.echarts.init(document.getElementById('city-counts-trend'));
      var option = {
            backgroundColor: '#F8F8F8',
            label:{show:true,position: 'left'},
            title: {
                text: '活跃岗位数趋势图'
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: this.data_citytrend.series.map(item => item.name)
            },
            grid: {
                left: '3%',
                right: '3%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                // boundaryGap: false,
                data: this.data_citytrend.xdata
            },
            yAxis: {
                type: 'value',
                min:100
            },
            series: this.data_citytrend.series
            };
      myChart.setOption(option);
    },
    citycountsbarchart(){
      var myChart = window.echarts.init(document.getElementById('city-counts-bar'));
      var option = {
          color:'#63b2ee',
          label:{"show":true},
          title: {
                text: '各城市活跃岗位数量分布'
          },
          tooltip: {
                trigger: 'item'
            },
          backgroundColor: '#F8F8F8',
          xAxis: {
            type: 'category',
            data: this.data_cityinfo.xdata
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: this.data_cityinfo.series_counts,
              type: 'bar'
            }
          ]
        };
        myChart.setOption(option);
    },
    citysalarybarchart(){
      var myChart = window.echarts.init(document.getElementById('city-salary-bar'));
      var option = {
          color:'#76da91',
          label:{"show":true,formatter: '{c}K'},
          title: {
                text: '各城市活跃岗位平均薪资'
          },
          tooltip: {
                trigger: 'item'
            },
          backgroundColor: '#F8F8F8',
          xAxis: {
            type: 'category',
            data: this.data_cityinfo.xdata
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: this.data_cityinfo.series_salary,
              type: 'bar'
            }
          ]
        };
        myChart.setOption(option);
    },
    bussinesscountssbarchart(){
      var myChart = window.echarts.init(document.getElementById('bussiness-counts-bar'));
      var option = {
        color:'#f8cb7f',
        label:{"show":true},
        title: {
              text: '各行业活跃岗位数据分布'
        },
        tooltip: {
                trigger: 'item'
            },
        backgroundColor: '#F8F8F8',

        xAxis: {
          type: 'value',
        },
        yAxis: {
          type: 'category',
          data: this.data_bussiness.ydata
        },
        series: [
          {
            type: 'bar',
            data: this.data_bussiness.series_counts
          },
        ]
      };
        myChart.setOption(option);
    },
    bussinesssalarysbarchart(){
      var myChart = window.echarts.init(document.getElementById('bussiness-salary-bar'));
      var option = {
        color:'#7cd6cf',
        label:{"show":true,formatter: '{c}K'},
        title: {
              text: '各行业活跃岗位平均薪资'
        },
        tooltip: {
                trigger: 'item'
            },
        backgroundColor: '#F8F8F8',

        xAxis: {
          type: 'value',
        },
        yAxis: {
          type: 'category',
          data: this.data_bussiness.ydata
        },
        series: [
          {
            type: 'bar',
            data: this.data_bussiness.series_salary
          },
        ]
      };
        myChart.setOption(option);
    },
    educationcountspiechart(){
      var myChart = window.echarts.init(document.getElementById('education-counts-pie'));
      var option  = {
          title: {
                text: '各学历活跃岗位分布'
          },
          backgroundColor: '#F8F8F8',
          tooltip: {
            trigger: 'item'
          },
          label:{"show":true,formatter: '{b}：{d}%'},
          color:['#63b2ee','#76da91','#f8cb7f','#f89588','#7cd6cf','#7898e1','#efa666','#eddd86','#9987ce'],
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: '50%',
              data: this.data_educationinfo.series_counts,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                }
              }
            }
          ]
        };
      myChart.setOption(option);
    },
    educationsalarybarchart(){
      var myChart = window.echarts.init(document.getElementById('education-salary-bar'));
      var option = {
          title: {
                text: '各学历活跃岗位平均薪资'
          },
          tooltip: {
                trigger: 'item'
            },
          label:{"show":true,formatter: '{c}K'},
          backgroundColor: '#F8F8F8',
          color:'#f89588',
          xAxis: {
            type: 'category',
            data: this.data_educationinfo.xdata
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: this.data_educationinfo.series_salary,
              type: 'bar'
            }
          ]
        };
      myChart.setOption(option);
    },
    workyearcountspiechart(){
      var myChart = window.echarts.init(document.getElementById('workyear-counts-pie'));
      var option = {
          title: {
                text: '各工作年限活跃岗位分布'
          },
          label:{"show":true,formatter: '{b}：{d}%'},
          color:['#63b2ee','#76da91','#f8cb7f','#f89588','#7cd6cf','#7898e1','#efa666','#eddd86','#9987ce'],
          backgroundColor: '#F8F8F8',
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: '50%',
              data: this.data_workyear.series_counts,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
      myChart.setOption(option);
    },
    workyearsalarybarchart(){
      var myChart = window.echarts.init(document.getElementById('workyear-salary-bar'));

      var option = {
          title: {
                text: '各工作年限活跃岗位平均薪资'
          },
          label:{"show":true,formatter: '{c}K'},
          tooltip: {
                trigger: 'item'
            },
          backgroundColor: '#F8F8F8',
          color:'#9987ce',
          xAxis: {
            type: 'category',
            data: this.data_workyear.xdata
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: this.data_workyear.series_salary,
              type: 'bar'
            }
          ]
        };
      myChart.setOption(option);
    },
    cscountsbarchart(){
      var myChart = window.echarts.init(document.getElementById('cs-counts-bar'));
      var option = {
          title: {
                text: '各公司规模活跃岗位分布'
          },
          tooltip: {
                trigger: 'item'
            },
          label:{"show":true},
          backgroundColor: '#F8F8F8',
          color:'#efa666',
          xAxis: {
            type: 'category',
            data: this.data_companysize.xdata
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: this.data_companysize.series_counts,
              type: 'bar'
            }
          ]
        };
      myChart.setOption(option);
    },
    skillwordcloudchart(){
        var chart = echarts.init(document.getElementById('skill-wordcloud'));
        chart.setOption({
                title: {
                      text: '工作技能要求词云'
                },
                tooltip: {
                    trigger: 'item'
                },
                backgroundColor: '#F8F8F8',
                color:['#63b2ee','#76da91','#f8cb7f','#f89588','#7cd6cf','#7898e1','#efa666','#eddd86','#9987ce'],
                series:[{
                sizeRange: [20, 52.42],
                size: ['100%', '100%'], // 设置显示的字云图的大小
                rotationRange: [0, 0], // 设置文字倾斜角度
                textPadding: 30, // 设置文字之间的间距
                type: 'wordCloud',
                shape: 'pentagon',// 词云形状，默认circle(圆形)，cardioid（心形）、diamond（菱形 正方形）、triangle-forward、triangle（三角形）、star（星形）、pentagon （五边形）；
                gridSize: 20,
                textStyle: {
                  normal: {
                    fontFamily: '微软雅黑',
                    // fontWeight: 'bold',
                    color: function() {
                      // Random color
                      return 'rgb(' + [
                        Math.round(Math.random() * 200),
                        Math.round(Math.random() * 250),
                        Math.round(Math.random() * 250)
                      ].join(',') + ')';
                    }
                  }
                },
                //data属性中的value值却大，权重就却大，展示字体就却大
                data:this.data_skillword
            }]
        })
    },
    uselesscountsbarchart(){
      var myChart = window.echarts.init(document.getElementById('useless-counts-bar'));
      var option = {
        backgroundColor: '#F8F8F8',
        tooltip: {
                trigger: 'item'
            },
        label:{"show":true},
        color:'#eddd86',
        title: {
          text: '各城市常年挂着的岗位分布'
        },
        xAxis: {
          type: 'value',
        },
        yAxis: {
          type: 'category',
          data: this.data_uselesscount.ydata
        },
        series: [
          {
            type: 'bar',
            data: this.data_uselesscount.series_counts
          },
        ]
      };
        myChart.setOption(option);
    },
  },
  mounted(){
    this.getusermessage();
    this.getjobtrenddata();
    this.getcitytrenddata();
    this.getcityinfodata();
    this.getbussinessinfodata();
    this.geteducationinfodata();
    this.getworkyearinfodata();
    this.getcompanysizedata();
    this.getskillwordclouddata();
    this.getuselesscountdata();
  },
}
</script>


<style scoped lang='scss'>

  .header {
    height: 60px;
    padding-left:0px !important;
    background: #eaeff5;
    display: flex;
    border-bottom: 1px solid #c0c0c0;
    .brand {
      height: 60px;
      width: 200px;
      margin-left: -20px;
      font-size: 20px;
      color: #000000 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header-a{
        height: 60px;
        width: 60px;
        font-size: 14px;
        color: #18222a !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }

</style>

<style scoped>


.header-content {
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: #003366;
  }

.zhuce{
  margin-right:20px;
  color:black !important;
}
.denglu{
  color:black !important;
}

.main{
  background: #eaeff5;
  position:absolute;
  margin:0 auto;
  top:60px;
  width:100%;
  height: calc(100vh - 60px);
}

.team-members{
    width:800px;
    height: calc(100vh - 120px);
    margin: 0 auto;
}

.team-title{
    margin-top: 2%;
    color: #273849;
    font-weight: 600;
}

.avatar{
    flex:0 0 80px;
}
.avatar-img{
    border-radius:50%,
}
.avatar>img{
    border-radius:50%;
    object-fit: cover;
}
.profile{
    padding-left:26px;
    flex:1;
}
.dathonuser{
    display:flex;
}
.profile>h3{
    /* font-size:1.3em;
    font-weight:600; */
    color:#273849;
    margin:0px 0px 16px 0px;
}
.team-link{
    color:#42b983 !important;
    font-weight:600;
    font-size:14px;
}

/* header css */
.ul-link{
  display: flex;
  flex-direction:row;
}
.header-link{
  display: flex;
}
.el-badge__content.is-fixed{
  transform:translateY(-60%) translateX(120%) !important;
}

.loading-container {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7); /* 通过rgba设置白色背景并调整透明度 */
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  background-color:#f8f8f8;
}
.table-title {
  font-family: 'Microsoft YaHei', sans-serif; /* 调整字体为微软雅黑 */
  color:#505050; /* 设定字体颜色为灰色，这里的颜色值可以根据你的需要调整 */
}

</style>