<template>
<div calss="selector">
  <div class='exe24' id='exe24'>
      <p style="padding:0px 0px 10px 0px" class="titlefont">24、查询所有同学里面在2022年3月4日之后最先过生日的同学学号、姓名、出生年月</p>
      <span  class="zwfont">
        难度 
        <el-tag type="warning" size="small">中等</el-tag>
      </span>
      <span class="zwfont" style="margin-left:20px">
        <el-button type="primary" icon="el-icon-share" size="mini" @click="sharelink()">分享本题</el-button>
      </span>
      <el-divider></el-divider>
  </div>
  <p class="titlefont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-idea" style="color:#409EFF"></i>
    题目描述:
  </p>
  <p class="titlefont" style="padding:0px 0px 10px 0px">
    查询所有同学里面在2022年3月4日之后最先过生日的同学学号、姓名、出生年月</p>
  <br/>

  <p class="titlefont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-ziduan" style="color:#409EFF"></i>
    字段要求及顺序
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    1.&nbsp;学号（sid）
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    2.&nbsp;姓名（sname）
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    3.&nbsp;年龄（sage）
  </p>
  <br/>

  <p class="titlefont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-shuju" style="color:#409EFF"></i>
    示例数据:
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-tips" style="color:#409EFF"></i>
    1.本题不一定用到以下全部表.&nbsp;&nbsp;
    &nbsp;
    2.表名需注意大小写.&nbsp;&nbsp;
    &nbsp;3.示例数据仅为部分数据，非全部数据.
  </p>
  <br/>
  <p class="titlefont">
    <i class="iconfont icon-biaoge" style="color:#409EFF"></i>
     学生成绩表. &nbsp; 表名：sc 
  </p>
  <el-table :data="scdataList"  v-loading="loading" stripe>
          <el-table-column :label="head" v-for="(head, key) in scheader" :key="head" :prop="key">
          </el-table-column>
  </el-table>
  <br/>
  <br/>
  <p class="titlefont">
    <i class="iconfont icon-biaoge" style="color:#409EFF"></i>
     课程信息表. &nbsp; 表名：course 
  </p>
  <el-table :data="coursedataList"  v-loading="loading" stripe>
          <el-table-column :label="head" v-for="(head, key) in courseheader" :key="head" :prop="key">
          </el-table-column>
  </el-table>
  <br/>
  <br/>
  <p class="titlefont">
    <i class="iconfont icon-biaoge" style="color:#409EFF"></i>
     学生信息表. &nbsp; 表名：student 
  </p>
  <el-table :data="studentdataList"  v-loading="loading" stripe>
          <el-table-column :label="head" v-for="(head, key) in studentheader" :key="head" :prop="key">
          </el-table-column>
  </el-table>
  
  <br/>
  <p class="titlefont">
    <i class="iconfont icon-biaoge" style="color:#409EFF"></i>
     老师信息表. &nbsp; 表名：teacher 
  </p>
  <el-table :data="teacherdataList"  v-loading="loading" stripe>
          <el-table-column :label="head" v-for="(head, key) in teacherheader" :key="head" :prop="key">
          </el-table-column>
  </el-table>
<br/>
</div>
</template>

<script type="text/ecmascript-6">

// import axios from "axios"

export default {
  name:"",
  data(){
    return {
      loading:false,
      scheader:{sid:'sid',cid:'cid',score:'score',},
      scdataList:[{sid:'01',cid:'01',score:'80'},{sid:'01',cid:'02',score:'90'},{sid:'01',cid:'03',score:99},
                  {sid:'02',cid:'01',score:'70'},{sid:'02',cid:'02',score:'60'}],
      courseheader:{cid:'cid',cname:'cname',tid:'tid'},
      coursedataList:[{cid:'01',cname:'语文',tid:'02'},
                      {cid:'02',cname:'数学',tid:'01'},
                      {cid:'03',cname:'英语',tid:'03'}],
      studentheader:{sid:'sid',sname:'sname',sage:'sage',ssex:'ssex'},
      studentdataList:[{sid:'01',sname:'赵雷',sage:'1990-01-01 00:00:00',ssex:'男'},
                      {sid:'02',sname:'钱电',sage:'1990-12-21 00:00:00',ssex:'男'},
                      {sid:'03',sname:'孙风',sage:'1990-05-20 00:00:00',ssex:'男'},
                      {sid:'04',sname:'李云',sage:'1990-08-06 00:00:00',ssex:'男'},
                      {sid:'05',sname:'周梅',sage:'1991-12-01 00:00:00',ssex:'女'}],
      teacherheader:{tid:'tid',tname:'tname'},
      teacherdataList:[{tid:'01','tname':'张三'},
                       {tid:'02','tname':'李四'},
                       {tid:'03','tname':'王五'}], 
    }
  },
  components:{},
    methods: {
      sharelink(){
        var link = window.location.href;

        this.copyContent = link
        var input = document.createElement("input"); // 直接构建input
        input.value = this.copyContent; // 设置内容
        console.log(input.value);
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例

         this.$message({
          message: '已复制本题链接，欢迎分享!',
          type: 'success'
         })
      }
    // tablegain(table,header,dataList){

    //   // let table = "sc";

  //     axios.get("https://dathonlearn.cn/primarytablegain",
  //     {
  //       headers:{
  //       'Content-Type':'application/x-www-form-urlencoded'
  //     },
  //     params:table}
  //     ).then(res=>{
  //       var dataList_temp = []
  //       dataList_temp = res.data.data
  //       for ( var j in dataList_temp){
  //         dataList.push(dataList_temp[j])
  //       }
  //       for (var x in res.data.data[0]){
  //         header[x] = x
  //       }
  //     })
  //   },
  //   },
  // mounted() {
      // this.tablegain("sc",this.scheader,this.scdataList);
      // console.log('scheader',this.scheader)
      // console.log('scdataList',this.scdataList)
      // this.tablegain("course",this.courseheader,this.coursedataList);
      // this.tablegain("student",this.studentheader,this.studentdataList);
      // this.tablegain("teacher",this.teacherheader,this.teacherdataList);
      }
}
</script>

<style scoped lang='scss'>
 
.selector{ font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif; };

.titlefont{
  color:#303133;
  font-size:16px;
  font-weight:600;
}

.zwfont{
  color:#303133;
  font-size:14px;
}

</style>