<template>

  <div class="login-box" id="app">
    <div id="error1"></div>
    <h1 class='title'>登录页</h1>
    <el-row>
      <el-col :span="8">
        <el-input id="name" v-model="username" placeholder="请输入邮箱">
          <template slot="prepend">邮箱</template>
        </el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-input
          id="password"
          v-model="password"
          type="password"
          placeholder="请输入密码"
        >
          <template slot="prepend">密码</template>
        </el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-button
          id="login"
          v-on:click="login(),openFullScreen1()"
          v-loading.fullscreen.lock="fullscreenLoading"
          style="width: 100%"
          type="primary"
          >登录</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
          <el-link  type="primary" class="linkblack"  href="#/">返回首页</el-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      username: "",
      password: "",
      fullscreenLoading: false
    };
  },
  methods: {
    login() {
      let login_data = "";
      login_data = { username: this.username, password: this.password };

      axios
        .post("https://dathonlearn.cn/users/login/", {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: login_data,
        })
        .then((res) => {
          if (res.data.status === 200) {
            let token = "";
            token = res.data.token;
            localStorage.setItem("Authorization", token);
            localStorage.setItem("username", res.data.username);
            this.$router.push("/");
          } else {
            this.$alert('邮箱账号或密码错误', '错误', {
          type:"error",
          });
            // alert("账号密码错误");
          }
        });
    },
      openFullScreen1() {
        this.fullscreenLoading = true;
        setTimeout(() => {
          this.fullscreenLoading = false;
        }, 2000);
      },
  },
};
</script>

<style>

.el-row {
    margin-bottom: 20px;
  }
	.login-box {
		margin-top:10%;
		margin-left:40%;
	}
.title{
  margin-bottom:20px;
}

.el-message-box__wrapper{
  top:30px !important;
  bottom: unset !important;
}

.linkblack{
  color:black !important;
}
</style>