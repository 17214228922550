<template>
  <div class="answer-main">
      <p class="answer-thinkings-title">
          解题思路
      </p>

      <div v-if="advise==='normal'" class="answer-div">

        <i class="iconfont icon-jingbaotishi" style="color:#409EFF"></i>

        <p class="answer-thinkings" style="white-space:pre-wrap;"> 
            因本题较为简单，解题思路忽略，若同学未能解答，建议加强SQL语法学习噢^ ^&nbsp;&nbsp;&nbsp;
        </p>
        <a class="answer-a" href="https://www.w3school.com.cn/sql/index.asp" target="_blank" style="color:#409EFF">
        <i class="iconfont icon-tiaozhuan-zhuanqu" style="color:#409EFF"></i>
            Go W3C-SQL
        </a>
      </div>
      
      <div v-else class="answer-div" style="white-space: pre-line;">
          <!-- <p class="answer-thinkings" style=" margin:10px 10px 10px 10px;"> -->
              {{advise}}
          <!-- </p> -->
      </div>
      <br/>

      <p class="answer-thinkings-title">
          答案代码
      </p>
      <el-button type="primary" icon="el-icon-document-copy" size="mini" @click="codecopy()" style="margin:10px 0px 10px 10px">复制代码</el-button>
      <div class="answerimg" style=" margin:0px 10px 0px 10px;" id="answerimg">
        <img :src="this.answerimg"/> 
        <!-- <img src="https://s4.ax1x.com/2022/01/19/7yPQjs.png"/> -->
      </div>
      <br/>
      <p class="answer-thinkings-title">
          答案结果
      </p>
      <br/>
      <div style="margin:0px 10px 0px 10px;">
        <el-table :data="showdatalist"  v-loading="loading"  stripe :header-cell-style="headClass" :cell-style="rowClass" >
                <el-table-column :label="head" v-for="(head, key) in showdataheader" :key="head" :prop="key">
                </el-table-column>
        </el-table>
      </div>
      <br/>

      <p class="answer-thinkings-title">
          欢迎加入Dathon数据分析交流群
      </p>
      <br/>
      <div class="erweima" >
        <img src="https://s4.ax1x.com/2022/01/23/74P9Te.jpg" style="width:100%; margin:0px 10px 0px 10px; height:100%;"/> 
      </div>

  </div>
</template>

<script>

import axios from "axios"

export default {
    data(){
    return{
       showdatalist:[],
       showdataheader:{},
       answerimg:'',
       advise:'',
       loading:false,
       exenum:''
        }
    },
    methods:{
        
        codecopy(){
            var exetitle = document.getElementById("exercise_body").children[0].firstChild._prevClass

            var data = {"username":localStorage.getItem('username'),"exetitle":exetitle}

            axios.post("https://dathonlearn.cn/codecopy",
            {
                headers:{
                'Content-Type':'application/x-www-form-urlencoded'
            },
            data:data}
            ).then(res=>{

                var code = res.data.data
                this.copyContent = code
                var input = document.createElement("input"); // 直接构建input
                input.value = this.copyContent; // 设置内容

                document.body.appendChild(input); // 添加临时实例
                input.select(); // 选择实例内容
                document.execCommand("Copy"); // 执行复制
                document.body.removeChild(input); // 删除临时实例

                this.$message({
                message: '已复制，Ctrl+V粘贴，格式较乱，建议使用SQL格式化功能!',
                type: 'success'
                })

            })
        },

        imgstylechange(){
                let exeid = document.getElementById("exercise_body").children[0].firstChild._prevClass
                let exenum = exeid.slice(3,exeid.length)

                if((exenum<=25)){
                    document.getElementById("answerimg").style.width = "550px";
                    document.getElementById("answerimg").style.height = "450px";
                }else if(
                    exenum==42||exenum==43||exenum==44||exenum==49
                ){
                    document.getElementById("answerimg").style.width = "550px";
                    document.getElementById("answerimg").style.height = "450px";
                }
                else{
                    document.getElementById("answerimg").style.width = "890px";
                    document.getElementById("answerimg").style.height = "820px";
                }
        },

        answeralldatagain(exeid){
            if(exeid===0){
                let exeid = document.getElementById("exercise_body").children[0].firstChild._prevClass

                axios
                    .post("https://dathonlearn.cn/answeralldataget", {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: exeid,
                    })
                    .then((res) => {

                        this.answerimg = res.data.data.answerimg
                        this.advise = res.data.data.advise
                    }),
                
                axios.post("https://dathonlearn.cn/showanswerdatagain",
                    {
                        headers:{
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    data:exeid}
                    ).then(res=>{


                        this.showdataheader = {}
                        for (var x in res.data.data[0]){
                        this.showdataheader[x] = x
                        }
                        this.showdatalist = res.data.data
                    })
        }else{
                axios
                    .post("https://dathonlearn.cn/answeralldataget", {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: exeid,
                    })
                    .then((res) => {

                        this.answerimg = res.data.data.answerimg
                        this.advise = res.data.data.advise
                    }),
                
                axios.post("https://dathonlearn.cn/showanswerdatagain",
                    {
                        headers:{
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    data:exeid}
                    ).then(res=>{

                        this.showdataheader = {}
                        for (var x in res.data.data[0]){
                        this.showdataheader[x] = x
                        }
                        this.showdatalist = res.data.data
                    })
             }
        
        },

        // answerdatagain(){
        //     var title = ''
        //     title = document.getElementById("exercise_body").children[0].firstChild._prevClass

        //     axios.post("https://dathonlearn.cn/showanswerdatagain",
        //         {
        //             headers:{
        //             'Content-Type':'application/x-www-form-urlencoded'
        //         },
        //         data:title}
        //         ).then(res=>{
        //             console.log('res=>',res.data);


        //             this.showdataheader = {}
        //             for (var x in res.data.data[0]){
        //             this.showdataheader[x] = x
        //             }

        //             this.showdatalist = res.data.data
        //         })
        //     },
        headClass(){
            return 'text-align: center;background:#eef1f6;'
        },
        rowClass () {
                return 'text-align: center;'
        }
    },
    mounted(){
        // this.answerdatagain()
        this.answeralldatagain(0)
        this.imgstylechange()
    },
    watch:{
        "$route.path":function(to,from){
            console.log(from)
            let exeid = to.slice(5,to.length)
            let exenum = to.slice(8,to.length)


            if(exenum<=25){
                document.getElementById("answerimg").style.width = "550px";
                document.getElementById("answerimg").style.height = "450px";

                this.answeralldatagain(exeid)

            }else if(
                    exenum==42||exenum==43||exenum==44||exenum==49
                ){
                    document.getElementById("answerimg").style.width = "550px";
                    document.getElementById("answerimg").style.height = "450px";
                    this.answeralldatagain(exeid)
                }
            else{
                document.getElementById("answerimg").style.width = "890px";
                document.getElementById("answerimg").style.height = "820px";

                this.answeralldatagain(exeid)

            }
        }
    }
}
</script>

<style>
.answer-thinkings-title{
    /* width: 98%; */
    border-radius: 4px;
    /* border-style: dotted; */
    text-align: center;
    background-color:#6FA8DC;
    /* position: relative; */
    margin: 0px 10px 0px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    color:#fff;
    font-size:16px;
    font-weight:600;
}
.answer-thinkings{
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); */
    margin: 5px;
    font-size:16px;
}

.answer-div{
    position:relative;
    margin: 10px 10px 0px 10px;
    background-color:rgba(240, 240, 240, 0.64);
    min-height: 30px;
    display:flex;
    align-items:center;
}
.answer-a{
    color:#409EFF !important;
}


.answerimg{
    width: 550px;
    height: 450px;
}

.answerimg>img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.erweima{
    width: 240px;
    height: 240px;

}
/* .answerimg>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
} */
</style>