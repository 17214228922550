<template>
  <div  >
      <el-container >
      <el-header class="header">

        <ul class="ul-link">
          <li style="list-style:none;width:120px;margin-right:40px">
            <a href="/" class="brand">
              <img src="../../src/dathonicon/blackicon.png" style="height:26px;width:120px;"/>
            </a>
          </li>
          <li style="list-style:none;">
            <a href="https://mp.weixin.qq.com/s/t6HjNyGVKsG8kU77r4r6Sg" class="header-a" target="_blank"> 
            <el-badge value="hot" class="item">
                课程
            </el-badge>
            </a>
          </li>
          <li style="list-style:none;">
            <a href="/#/vip" class="header-a">会员</a>
          </li>
          <li style="list-style:none;">
            <div href="/" class="header-a" style="">
            <el-dropdown>
              <span class="el-dropdown-link" style="color:#18222a !important">
                题库<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button type="text" @click="gosql">SQL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
                  </el-dropdown-item>
                <el-dropdown-item>Python（尚未完成）</el-dropdown-item>
                <el-dropdown-item>统计学（尚未完成）</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            </div>
          </li>
          <li style="list-style:none;">
            <a href="/#/team" class="header-a">团队</a>
          </li>
          <li style="list-style:none;">
            <a href="/#/dashboard" class="header-a">行情</a>
          </li>
        </ul>

        <span style="flex:1"></span>

        <div class="header-content" v-if="token===null">
          <el-link  type="primary" class='zhuce' href="#/login">微信登录</el-link>
          <el-link  type="primary" class='denglu' href="#/zhlogin">账号登录</el-link>
          <!-- <a class='zhuce' >注册</a>
          <a href="#/login" class='denglu'>登录</a> -->
        </div>
        <div v-else class="header-content">
          <el-dropdown @command="onLogout" class="touxiang">
            <span class="el-dropdown-link">
              <el-avatar :src=this.touxiang></el-avatar>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >{{username}}</el-dropdown-item>
              <el-dropdown-item command=1>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>


          <el-dropdown @command="onLogout" class="vip" v-if="restime>0">
            <span class="el-dropdown-link" >
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='xiaoni'"></i>
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='139746406@qq.com'"></i>
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='644721169@qq.com'"></i>
                  <i class="iconfont icon-vip" style="color:red;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >您Vip将在{{restime}}天后过期</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>
      </el-header>

        <el-main class='main'>
            <div class="team-members">
                <h1 class="team-title">Dathon小伙伴</h1>
                <el-divider></el-divider>
                <div class="dathonuser">
                    <div class="avatar">
                        <img  src="https://i.postimg.cc/sXCbpptL/1642584356-1.png" alt="Xiao ni" width="120" height="120">
                    </div>
                    <div class="profile">
                        <h3>小&nbsp;匿</h3>
                        <div class="xiaonistep">
                            <h4 style="color:#273849;">会计 - 数据分析师 - 数据挖掘工程师 - 数据科学全栈工程师</h4>
                            <h5 style="color:#42b983">2016&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2018&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2020&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;future</h5>
                        </div>
                        <div style="margin-top:16px;">
                        <h5 >
                            <i class="iconfont icon--kaifa" ></i>
                            &nbsp;Creator @ Dathon
                        </h5>
                        </div>
                        <div style="margin-top:16px;">
                        <h5>
                            <i class="iconfont icon-work" ></i>
                            &nbsp;数据挖掘工程师 @ 知名互联网厂
                        </h5>
                        </div>
                        <div style="margin-top:16px;">
                            <i class="iconfont icon-lianjie" ></i>
                            &nbsp;<a href="https://www.zhihu.com/people/shi-bu-shi-sha-97" class="team-link" target="_blank">
                            知乎
                            </a>
                            &nbsp;<a href="https://space.bilibili.com/865738" class="team-link" target="_blank">
                            B站
                            </a>
                            &nbsp;<a href="https://mp.weixin.qq.com/s/ZTCtkFJFnPHCDpSv_q87sg" class="team-link" target="_blank">
                            公众号
                            </a>
                        </div>
                    </div> 
                </div>
                <el-divider></el-divider>
                  <div class="dathonuser">
                    <div class="avatar">
                        <img  src="https://i.postimg.cc/yY7tkKZz/5585e0c3fa6fae4048b18d6333a2e1b.jpg" alt="Ocean" width="120" height="120">
                    </div>
                    <div class="profile">
                        <h3>Ocean</h3>
                        <div style="margin-top:16px;">
                        <h5>
                            <i class="iconfont icon-work" ></i>
                            &nbsp;数据分析师 @ 知名互联网厂
                        </h5>
                        </div>
                        <div style="margin-top:16px;">
                            <i class="iconfont icon-lianjie" ></i>
                            &nbsp;<a href="https://www.zhihu.com/people/ou-xin-7/answers" class="team-link" target="_blank">
                            知乎
                            </a>
                        </div>
                    </div>
                  </div>
                  <el-divider></el-divider>
                  <div class="dathonuser">
                    <div class="avatar">
                        <img  src="https://i.postimg.cc/J4krBHYQ/6ad2d0139d1e3f1a65b13e5ff9e0b96.jpg"  width="120" height="120">
                    </div>
                    <div class="profile">
                        <h3>赖安酱</h3>
                        <div style="margin-top:16px;">
                        <h5>
                            <i class="iconfont icon-work" ></i>
                            &nbsp;数据分析师 @ 某游戏厂
                        </h5>
                        </div>
                        <div style="margin-top:16px;">
                            <i class="iconfont icon-lianjie" ></i>
                            <el-tooltip placement="top">
                            <div slot="content">
                              <span>ryanchenheart@hotmail.com</span>
                            </div>
                            <span style="font-size:14px; color:#42b983;font-weight:600;">&nbsp;&nbsp;Email</span>
                          </el-tooltip>
                        </div>
                    </div>
                  </div>
                <el-divider></el-divider>
                  <div style="margin-top:16px;">
                    <h5>
                        <i class="iconfont icon-xiexie" ></i>
                        &nbsp;特别鸣谢：heima、教练、顾、以及各位帮忙测试的Dathoner
                    </h5>
                  </div>
            </div>
        </el-main>

    </el-container>
  </div>

</template>

<script>

import axios from "axios"

export default {
  name:"",
  created(){
    this.token = localStorage.getItem("Authorization")
    this.username = localStorage.getItem('username')
  },
  data(){
    return{
      token:"",
      username:"",
      touxiang:"",
      restime:"",
    }
  },
  methods:{
    onLogout(command){

      if (command==1){
        localStorage.removeItem("Authorization")
        localStorage.removeItem("headimg")
        localStorage.removeItem("username")
        localStorage.removeItem("dalay")
        localStorage.removeItem("_time")
        localStorage.removeItem("nickname")
        // remove所有存储
        this.$router.replace("/login")
      }
    },
    getusermessage(){
      var username = {"username":localStorage.getItem('username')}

      axios.post("https://dathonlearn.cn/users/getusermessage/",
      {
        headers:{
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:username}
      ).then(res=>{

        this.touxiang = res.data.data.touxiang
        this.restime = res.data.data.restime

        // 与评论共享数据
        localStorage.setItem("nickname",res.data.data.nickname)
        localStorage.setItem("headimg",res.data.data.touxiang)
        localStorage.setItem("restime",res.data.data.restime)
      })
    },
    gosql(){
      this.$router.push("/sql/exe18")
    }
  },
  mounted(){
    this.getusermessage()
  }
}
</script>


<style scoped lang='scss'>

  .header {
    height: 60px;
    padding-left:0px !important;
    background: #eaeff5;
    display: flex;
    border-bottom: 1px solid #c0c0c0;
    .brand {
      height: 60px;
      width: 200px;
      margin-left: -20px;
      font-size: 20px;
      color: #000000 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header-a{
        height: 60px;
        width: 60px;
        font-size: 14px;
        color: #18222a !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }

</style>

<style scoped>


.header-content {
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: #003366;
  }

.zhuce{
  margin-right:20px;
  color:black !important;
}
.denglu{
  color:black !important;
}

.main{
  background: #eaeff5;
  position:absolute;
  margin:0 auto;
  top:60px;
  width:100%;
  height: calc(100vh - 60px);
}

.team-members{
    width:800px;
    height: calc(100vh - 120px);
    margin: 0 auto;
}

.team-title{
    margin-top: 2%;
    color: #273849;
    font-weight: 600;
}

.avatar{
    flex:0 0 80px;
}
.avatar-img{
    border-radius:50%,
}
.avatar>img{
    border-radius:50%;
    object-fit: cover;
}
.profile{
    padding-left:26px;
    flex:1;
}
.dathonuser{
    display:flex;
}
.profile>h3{
    /* font-size:1.3em;
    font-weight:600; */
    color:#273849;
    margin:0px 0px 16px 0px;
}
.team-link{
    color:#42b983 !important;
    font-weight:600;
    font-size:14px;
}

/* header css */
.ul-link{
  display: flex;
  flex-direction:row;
}
.header-link{
  display: flex;
}
.el-badge__content.is-fixed{
  transform:translateY(-60%) translateX(120%) !important;
}
</style>