<template>
    <div > 
        <div v-clickoutside="hideReplyBtn" @click="inputFocus" class="my-reply">
            <el-avatar class="header-img" :size="40" :src="myHeader"></el-avatar>
            <div class="reply-info" >
                <div 
                tabindex="0" 
                contenteditable="true" 
                id="replyInput" 
                spellcheck="false" 
                placeholder="输入评论..." 
                class="reply-input" 
                @focus="showReplyBtn"  
                @input="onDivInput($event)"
                >
                </div>
            </div>
            <div class="reply-btn-box" v-show="btnShow">
                <el-button class="reply-btn" size="medium" @click="sendComment" type="primary">发表评论</el-button>
            </div>
        </div>

        <div v-for="(item,i) in comments" :key="i" class="author-title reply-father">
            <el-avatar class="header-img" :size="40" :src="item.headImg"></el-avatar>
            <div class="author-info">
                <span class="author-name">
                    {{item.name}}
                    <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="item.id=='xiaoni'"></i>
                    <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-else-if="item.id=='139746406@qq.com'"></i>
                    <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-else-if="item.id=='644721169@qq.com'"></i>
                    <i class="iconfont icon-vip" style="color:red;" v-else-if="item.vipclass!=='novip'"></i>
                </span>
                <span class="author-time">{{dateFormat(item.time)}}</span>
            </div>
            <div class="icon-btn">
                <span @click="showReplyInput(i,item.name,item.id,item.commentid)"><i class="iconfont el-icon-s-comment"></i></span>
                <!-- <i class="iconfont el-icon-caret-top"></i>{{item.like}} -->
            </div>
            <div class="talk-box">
                <p  >
                    <span class="reply" >{{item.comment}}</span>
                </p>
                <!-- <el-button type="text">删除评论</el-button> -->
            </div>
            <div class="reply-box">
                <div v-for="(reply,j) in item.reply" :key="j" class="author-title">
                    <el-avatar class="header-img" :size="40" :src="reply.fromHeadImg"></el-avatar>
                    <div class="author-info">
                        <span class="author-name">
                            {{reply.from}}
                            <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="reply.id=='xiaoni'"></i>
                            <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-else-if="reply.id=='139746406@qq.com'"></i>
                            <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-else-if="reply.id=='644721169@qq.com'"></i>
                            
                            <i class="iconfont icon-vip" style="color:red;" v-else-if="reply.vipclass!=='novip'"></i>
                        </span>
                        <span class="author-time">{{dateFormat(reply.time)}}</span>
                    </div>
                    <div class="icon-btn">
                        <span @click="showReplyInput(i,reply.from,reply.id,reply.commentid)"><i class="iconfont el-icon-s-comment"></i></span>
                        <!-- <i class="iconfont el-icon-caret-top"></i>{{reply.like}} -->
                    </div>
                    <div class="talk-box">
                        <p >
                            <span>回复 {{reply.to}}:</span>
                            <span class="reply">{{reply.comment}}</span>
                        </p>
                    </div>
                    <div class="reply-box">

                    </div>
                </div>
            </div>
            <div  v-show="_inputShow(i)" class="my-reply my-comment-reply">
                <el-avatar class="header-img" :size="40" :src="myHeader"></el-avatar>
                <div class="reply-info" >
                    <div tabindex="0" contenteditable="true" spellcheck="false" placeholder="输入评论..."   @input="onDivInput($event)"  class="reply-input reply-comment-input"></div>
                </div>
                <div class=" reply-btn-box">
                    <el-button class="reply-btn" size="medium" @click="sendCommentReply(i)" type="primary">发表评论</el-button>
            </div>
        </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import moment from 'moment'


const clickoutside = {
    // 初始化指令
    bind(el, binding, vnode) {
    function documentHandler(e) {
        console.log(vnode)
    // 这里判断点击的元素是否是本身，是本身，则返回
        if (el.contains(e.target)) {
            return false;
        }
    // 判断指令中是否绑定了函数
        if (binding.expression) {
            // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
            binding.value(e);
        }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.vueClickOutside = documentHandler;
    document.addEventListener('click', documentHandler);
    },
    update() {},
    unbind(el, binding) {
        console.log(binding)
    // 解除事件监听
    document.removeEventListener('click', el.vueClickOutside);
    delete el.vueClickOutside;
  },
};
export default {
    name:'ArticleComment',
    created(){
        this.myName = localStorage.getItem("nickname")
        this.myHeader = localStorage.getItem("headimg")
        this.myId = localStorage.getItem("username")
        this.restime = localStorage.getItem("restime")
    },
    data(){
        return{
            btnShow: false,
            index:'0',
            replyComment:'',
            // nickname
            myName:'',
            myHeader:'',
            // username
            myId:'', 
            // tonickname
            to:'',
            // tousername
            toId:-1,
            commentid:'',
            comments:'',
            restime:''
        }
    },
    directives: {clickoutside},
    methods: {
        inputFocus(){
            var replyInput = document.getElementById('replyInput');
            replyInput.style.padding= "8px 8px"
            replyInput.style.border ="2px solid blue"
            replyInput.focus()
        },  
        showReplyBtn(){
            this.btnShow = true
        },
        hideReplyBtn(){
            this.btnShow = false
            var replyInput = document.getElementById('replyInput');
            replyInput.style.padding= "10px"
            replyInput.style.border ="none"
        },
        showReplyInput(i,name,id,commentid){
            this.comments[this.index].inputShow = false
            this.index =i
            this.comments[i].inputShow = true
            this.to = name
            this.toId = id
            this.commentid = commentid
        },
        _inputShow(i){
            return this.comments[i].inputShow 
        },


        sendComment(){
            // 没登录不能评论，还得判断这个
            if(!this.replyComment){
                 this.$message({
                    showClose: true,
                    type:'warning',
                    message:'评论不能为空'
                })
            }else{
                // 评论id获取
                let exeid = document.getElementById("exercise_body").children[0].firstChild._prevClass

                let replyComment = this.replyComment

                axios
                    .post("https://dathonlearn.cn/commentidget", {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: exeid,
                    })
                    .then((res) => {
                        let commentid =''

                        commentid = res.data.commentid
 
                        let comment_data = {"exeid":document.getElementById("exercise_body").children[0].firstChild._prevClass,
                                        "username":localStorage.getItem('username'),
                                        "nickname":localStorage.getItem("nickname"),
                                        "headimg":localStorage.getItem("headimg"),
                                        "time":new Date().getTime(),
                                        "comment":replyComment,
                                        "tousername":-1,
                                        "tonickname":"",
                                        "commentid":commentid
                                        }
                        

                        axios
                            .post("https://dathonlearn.cn/commentsave", {
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded",
                            },
                            data: comment_data,
                            })
                            .then((res) => {
                                console.log(res.data)
                            })
                    })


                let a ={}
                let input =  document.getElementById('replyInput')
                let timeNow = new Date().getTime();
                let time= this.dateFormat(timeNow);
                a.name= this.myName
                a.comment =this.replyComment
                a.headImg = this.myHeader
                a.time = time
                a.id = this.myId
                if(this.restime>0){
                    a.vipclass = 'vip'
                }else{
                    a.vipclass = 'novip'
                }
                this.comments.push(a)

                this.replyComment = ''
                input.innerHTML = '';

            }
        },
        sendCommentReply(i){
            if(!this.replyComment){
                 this.$message({
                    showClose: true,
                    type:'warning',
                    message:'评论不能为空'
                })
            }else{
                
                let commentreply_data = {"exeid":document.getElementById("exercise_body").children[0].firstChild._prevClass,
                                "username":localStorage.getItem('username'),
                                "nickname":localStorage.getItem("nickname"),
                                "headimg":localStorage.getItem("headimg"),
                                "time":new Date().getTime(),
                                "comment":this.replyComment,
                                "tousername":this.toId,
                                "tonickname":this.to,
                                "commentid":this.commentid
                                }
                

                axios
                    .post("https://dathonlearn.cn/commentsave", {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: commentreply_data,
                    })
                    .then((res) => {
                        console.log(res.data)
                    })


                let a ={}
                let timeNow = new Date().getTime();
                let time= this.dateFormat(timeNow);
                a.from= this.myName
                a.to = this.to
                a.fromHeadImg = this.myHeader
                a.comment =this.replyComment
                if(this.restime>0){
                    a.vipclass = 'vip'
                }else{
                    a.vipclass = 'novip'
                }
                a.id = this.myId
                a.time = time
                this.comments[i].reply.push(a)
                this.replyComment = ''
                document.getElementsByClassName("reply-comment-input")[i].innerHTML = ""
            }
        },
        onDivInput: function(e) {
            this.replyComment = e.target.innerHTML;
        },
        dateStr(date){
            //获取js 时间戳
            var time=new Date().getTime();
            //去掉 js 时间戳后三位，与php 时间戳保持一致
            time=parseInt((time-date)/1000);
            //存储转换值 
            var s;
            if(time<60*10){//十分钟内
                return '刚刚';
            }else if((time<60*60)&&(time>=60*10)){
                //超过十分钟少于1小时
                s = Math.floor(time/60);
                return  s+"分钟前";
            }else if((time<60*60*24)&&(time>=60*60)){ 
                //超过1小时少于24小时
                s = Math.floor(time/60/60);
                return  s+"小时前";
            }else if((time<60*60*24*30)&&(time>=60*60*24)){ 
                //超过1天少于30天内
                s = Math.floor(time/60/60/24);
                return s+"天前";
            }else{ 
                //超过30天ddd
                var date2= new Date(parseInt(date));
                return date2.getFullYear()+"/"+(date2.getMonth()+1)+"/"+date2.getDate();
            }
        },

        commentdataget(exeid){
            // exeid===0代表刷新，而不是切换tab的场景
            if(exeid===0){
                let exeid = document.getElementById("exercise_body").children[0].firstChild._prevClass

                axios
                    .post("https://dathonlearn.cn/commentdataget", {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: exeid,
                    })
                    .then((res) => {
                        this.comments = res.data.data
                    })
            }
            else{
                axios
                    .post("https://dathonlearn.cn/commentdataget", {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: exeid,
                    })
                    .then((res) => {
                        this.comments = res.data.data
                    })
            }
        },
          dateFormat(date) {
            return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
        // getusermessage(){
        //     var username = {"username":localStorage.getItem('username')}

        //     axios.post("https://dathonlearn.cn/users/getusermessage/",
        //     {
        //         headers:{
        //         'Content-Type':'application/x-www-form-urlencoded'
        //     },
        //     data:username}
        //     ).then(res=>{
        //         console.log('res=>',res.data);
        //         this.myHeader = res.data.data.touxiang
        //         console.log('this.img',this.myHeader)
        //         localStorage.setItem("nickname",res.data.data.nickname)
        //         localStorage.setItem("headimg",res.data.data.touxiang)
                
        //         console.log(localStorage.getItem("nickname"))
        //     })
        //     }
    },
    mounted() {
        this.commentdataget(0)
        // this.getusermessage()
    },
    watch:{
        "$route.path":function(to,from){
            let exeid = to.slice(5,to.length)

            this.commentdataget(exeid)
            console.log(from)
            
            this.myName = localStorage.getItem("nickname")
            this.myHeader = localStorage.getItem("headimg")
            this.myId = localStorage.getItem("username")
        }
    }
}
</script>


<style lang="stylus" scoped>
    .my-reply
        padding 10px
        background-color #fafbfc
        .header-img
            display inline-block
            vertical-align top
        .reply-info    
            display inline-block
            margin-left 5px
            width 90%
            @media screen and (max-width:1200px) {
                width 80%
            }
            .reply-input
                min-height 20px
                line-height 22px
                padding 10px 10px
                color #ccc
                background-color #fff
                border-radius 5px
                &:empty:before
                    content attr(placeholder)
                &:focus:before
                    content none
                &:focus
                    padding 8px 8px
                    border 2px solid blue
                    box-shadow none
                    outline none
        .reply-btn-box
            height 25px
            margin 10px 0
            .reply-btn
                position relative
                float right
                margin-right 15px
    .my-comment-reply
        margin-left 50px
        .reply-input
            width flex
    .author-title:not(:last-child)
        border-bottom: 1px solid rgba(178,186,194,.3)
    .author-title
        padding 10px
        .header-img
            display inline-block
            vertical-align top
        .author-info
            display inline-block
            margin-left 5px
            width 60%
            height 40px
            line-height 20px
            >span 
                display block
                cursor pointer
                overflow hidden
                white-space nowrap
                text-overflow ellipsis
            .author-name
                color #000
                font-size 18px
                font-weight bold
            .author-time
                font-size 14px
        .icon-btn
            width 30%
            padding 0 !important 
            float right
            @media screen and (max-width : 1200px){
                width 20%
                padding 7px
            }
            >span 
                cursor pointer
            .iconfont 
                margin 0 5px
        .talk-box
            margin 0 50px
            >p
            margin 0
            .reply
                font-size 16px
                color #000
        .reply-box
            margin 10px 0 0 50px
            background-color #efefef
</style>
