 /* jshint esversion: 6 */ 
/*jshint -W033 */

import VueRouter  from "vue-router";
import Vue from "vue";
import Main from '@/components/Main';

import exe1 from '@/components/exercises/junior_exercises_1'
import exe2 from '@/components/exercises/junior_exercises_2'
import exe3 from '@/components/exercises/junior_exercises_3'
import exe4 from '@/components/exercises/junior_exercises_4'
import exe5 from '@/components/exercises/junior_exercises_5'
import exe6 from '@/components/exercises/junior_exercises_6'
import exe7 from '@/components/exercises/junior_exercises_7'
import exe8 from '@/components/exercises/junior_exercises_8'
import exe9 from '@/components/exercises/junior_exercises_9'
import exe10 from '@/components/exercises/junior_exercises_10'
import exe11 from '@/components/exercises/junior_exercises_11'
import exe12 from '@/components/exercises/junior_exercises_12'
import exe13 from '@/components/exercises/junior_exercises_13'
import exe14 from '@/components/exercises/junior_exercises_14'
import exe15 from '@/components/exercises/junior_exercises_15'
import exe16 from '@/components/exercises/junior_exercises_16'
import exe17 from '@/components/exercises/junior_exercises_17'
import exe18 from '@/components/exercises/junior_exercises_18'
import exe19 from '@/components/exercises/junior_exercises_19'
import exe20 from '@/components/exercises/junior_exercises_20'
import exe21 from '@/components/exercises/junior_exercises_21'
import exe22 from '@/components/exercises/junior_exercises_22'
import exe23 from '@/components/exercises/junior_exercises_23'
import exe24 from '@/components/exercises/junior_exercises_24'

import exe25 from '@/components/exercises/hard_exercises_25'
import exe26 from '@/components/exercises/hard_exercises_26'
import exe27 from '@/components/exercises/hard_exercises_27'
import exe28 from '@/components/exercises/hard_exercises_28'
import exe29 from '@/components/exercises/hard_exercises_29'
import exe30 from '@/components/exercises/hard_exercises_30'
import exe31 from '@/components/exercises/hard_exercises_31'
import exe32 from '@/components/exercises/hard_exercises_32'
import exe33 from '@/components/exercises/hard_exercises_33'

import exe34 from '@/components/exercises/hard_exercises_34'
import exe35 from '@/components/exercises/hard_exercises_35'
import exe36 from '@/components/exercises/hard_exercises_36'
import exe37 from '@/components/exercises/hard_exercises_37'
import exe38 from '@/components/exercises/hard_exercises_38'
import exe39 from '@/components/exercises/hard_exercises_39'
import exe40 from '@/components/exercises/hard_exercises_40'
import exe41 from '@/components/exercises/hard_exercises_41'
import exe42 from '@/components/exercises/taolu_exercises_42'
import exe43 from '@/components/exercises/taolu_exercises_43'
import exe44 from '@/components/exercises/taolu_exercises_44'
import exe45 from '@/components/exercises/taolu_exercises_45'
import exe46 from '@/components/exercises/taolu_exercises_46'
import exe47 from '@/components/exercises/taolu_exercises_47'
import exe48 from '@/components/exercises/taolu_exercises_48'
import exe49 from '@/components/exercises/taolu_exercises_49'
import exe50 from '@/components/exercises/taolu_exercises_50'
import exe51 from '@/components/exercises/taolu_exercises_51'
import exe52 from '@/components/exercises/taolu_exercises_52'


import Login from '@/components/WxLogin';
import ZhLogin from '@/components/Login';
// import Register from '@/components/Register'
import Comment from '@/components/Comment';
import Home from '@/components/Home';
import Team from '@/components/Team';
import Dashboard from '@/components/Dashboard';
import Vip from '@/components/Vip';
import Dathonclass from '@/components/Dathonclass';
import axios from "axios";

Vue.use(VueRouter);

const router = new VueRouter({
    components:{
        comment:Comment
    },
    routes:[
        {
            path:"/",
            component:Home,
            meta:{
                title:'Dathon数据分析刷题平台'
            }
        },
        {
            path:"/sql",
            component:Main,
            meta:{
                title:'Dathon数据分析刷题平台'
            },
            children:[
                {path: "exe1",component: exe1,name: "exe1"},
                {path: "exe2",component: exe2,name:"exe2"},
                {path: "exe3",component: exe3,name:"exe3"},
                {path: "exe4",component: exe4,name:"exe4"},
                {path: "exe5",component: exe5,name:"exe5"},
                {path: "exe6",component: exe6,name:"exe6"},
                {path: "exe7",component: exe7,name:"exe7"},
                {path: "exe8",component: exe8,name:"exe8"},
                {path: "exe9",component: exe9,name:"exe9"},
                {path: "exe10",component: exe10,name:"exe10"},
                {path: "exe11",component: exe11,name:"exe11"},
                {path: "exe12",component: exe12,name:"exe12"},
                {path: "exe13",component: exe13,name:"exe13"},
                {path: "exe14",component: exe14,name:"exe14"},
                {path: "exe15",component: exe15,name:"exe15"},
                {path: "exe16",component: exe16,name:"exe16"},
                {path: "exe17",component: exe17,name:"exe17"},
                {path: "exe18",component: exe18,name:"exe18"},
                {path: "exe19",component: exe19,name:"exe19"},
                {path: "exe20",component: exe20,name:"exe20"},
                {path: "exe21",component: exe21,name:"exe21"},
                {path: "exe22",component: exe22,name:"exe22"},
                {path: "exe23",component: exe23,name:"exe23"},
                {path: "exe24",component: exe24,name:"exe24"},
                {path:"exe25",component: exe25,name:"exe25"},
                {path:"exe26",component: exe26,name:"exe26"},
                {path:"exe27",component: exe27,name:"exe27"},
                {path:"exe28",component: exe28,name:"exe28"},
                {path:"exe29",component: exe29,name:"exe29"},
                {path:"exe30",component: exe30,name:"exe30"},
                {path:"exe31",component: exe31,name:"exe31"},
                {path:"exe32",component: exe32,name:"exe32"},
                {path:"exe33",component: exe33,name:"exe33"},
                {path:"exe34",component: exe34,name:"exe34"},
                {path:"exe35",component: exe35,name:"exe35"},
                {path:"exe36",component: exe36,name:"exe36"},
                {path:"exe37",component: exe37,name:"exe37"},
                {path:"exe38",component: exe38,name:"exe38"},
                {path:"exe39",component: exe39,name:"exe39"},
                {path:"exe40",component: exe40,name:"exe40"},
                {path:"exe41",component: exe41,name:"exe41"},
                {path:"exe42",component: exe42,name:"exe42"},
                {path:"exe43",component: exe43,name:"exe43"},
                {path:"exe44",component: exe44,name:"exe44"},
                {path:"exe45",component: exe45,name:"exe45"},
                {path:"exe46",component: exe46,name:"exe46"},
                {path:"exe47",component: exe47,name:"exe47"},
                {path:"exe48",component: exe48,name:"exe48"},
                {path:"exe49",component: exe49,name:"exe49"},
                {path:"exe50",component: exe50,name:"exe50"},
                {path:"exe51",component: exe51,name:"exe51"},
                {path:"exe52",component: exe52,name:"exe52"},
            ],
        },
        {
            path:"/login",
            meta:{
                title:'Dathon数据分析刷题平台'
            },
            component:Login
        },
        {
            path:"/zhlogin",
            meta:{
                title:'Dathon数据分析刷题平台'
            },
            component:ZhLogin
        },
        {
            path:"/comment",
            meta:{
                title:'Dathon数据分析刷题平台'
            },
            component:Comment
        },
        {
            path:"/team",
            meta:{
                title:'Dathon数据分析刷题平台'
            },
            component:Team
        },
        {
            path:"/dashboard",
            meta:{
                title:'Dathon数据分析刷题平台'
            },
            component:Dashboard
        },
        {
            path:"/vip",
            meta:{
                title:'Dathon数据分析刷题平台'
            },
            component:Vip
        },
        {
            path:"/dathonclass",
            meta:{
                title:'Dathon数据分析刷题平台'
            },
            component:Dathonclass
        },
    ]
});

router.beforeEach((to,from,next) =>{

    if(to.meta.title){
        document.title = to.meta.title
    }

    // if (window._hmt) {
    //     if (to.path) {
    //       window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    //     }
    //   }

    let token = localStorage.getItem("Authorization");

    let token_data = ""

    let restime = localStorage.getItem("restime")

    if(token!==null){

        token_data = {usertoken:token}

        axios
        .post("https://dathonlearn.cn/users/tokenjudge/", {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: token_data,
        })
        .then((res) => {

            let tokenjudge = ""
            tokenjudge = res.data.tokenjudge

            if(tokenjudge!==1&&to.path!=="/login"&&to.path!=="/"&&to.path!=="/zhlogin"&&to.path!=="/vip"&&to.path!=="/team"
                &&to.path!=="/dathonclass"
                &&to.path!=="/sql/exe18"
                &&to.path!=="/sql/exe2"
                &&to.path!=="/sql/exe19"
                &&to.path!=="/sql/exe4"
                &&to.path!=="/sql/exe23"
                &&to.path!=="/sql/exe17"
                &&to.path!=="/sql/exe7"
                &&to.path!=="/sql/exe12"
                &&to.path!=="/sql/exe9"
                &&to.path!=="/sql/exe20"
                &&to.path!=="/sql/exe21")
            {
                next("/login");
            }else if((restime<=0&&to.path==="/sql/exe27")
                ||(restime<=0&&to.path==="/sql/exe28")
                ||(restime<=0&&to.path==="/sql/exe29")
                ||(restime<=0&&to.path==="/sql/exe30")
                ||(restime<=0&&to.path==="/sql/exe31")
                ||(restime<=0&&to.path==="/sql/exe32")
                ||(restime<=0&&to.path==="/sql/exe33")
                ||(restime<=0&&to.path==="/sql/exe36")
                ||(restime<=0&&to.path==="/sql/exe37")
                ||(restime<=0&&to.path==="/sql/exe38")
                ||(restime<=0&&to.path==="/sql/exe39")
                ||(restime<=0&&to.path==="/sql/exe40")
                ||(restime<=0&&to.path==="/sql/exe41")
                ||(restime<=0&&to.path==="/sql/exe45")
                ||(restime<=0&&to.path==="/sql/exe46")
                ||(restime<=0&&to.path==="/sql/exe47")
                ||(restime<=0&&to.path==="/sql/exe48")
                ||(restime<=0&&to.path==="/sql/exe49")
                ||(restime<=0&&to.path==="/sql/exe50")
                ||(restime<=0&&to.path==="/sql/exe51")
                ||(restime<=0&&to.path==="/sql/exe52")
                )
                
            {
                // 跳去会员页
                next("/vip")
            }else{
                next()
            }
        })
    }else{
        {
            if(to.path!=="/login"&&to.path!=="/"&&to.path!=="/zhlogin"&&to.path!=="/vip"&&to.path!=="/team"
                &&to.path!=="/dathonclass"
                &&to.path!=="/sql/exe18"
                &&to.path!=="/sql/exe2"
                &&to.path!=="/sql/exe19"
                &&to.path!=="/sql/exe4"
                &&to.path!=="/sql/exe23"
                &&to.path!=="/sql/exe17"
                &&to.path!=="/sql/exe7"
                &&to.path!=="/sql/exe12"
                &&to.path!=="/sql/exe9"
                &&to.path!=="/sql/exe20"
                &&to.path!=="/sql/exe21")
            {
                next("/login");
            }else if((restime<=0&&to.path==="/sql/exe26")
                ||(restime<=0&&to.path==="/sql/exe27")
                ||(restime<=0&&to.path==="/sql/exe28")
                ||(restime<=0&&to.path==="/sql/exe29")
                ||(restime<=0&&to.path==="/sql/exe30")
                ||(restime<=0&&to.path==="/sql/exe31")
                ||(restime<=0&&to.path==="/sql/exe32")
                ||(restime<=0&&to.path==="/sql/exe33")
                ||(restime<=0&&to.path==="/sql/exe34")
                ||(restime<=0&&to.path==="/sql/exe35")
                ||(restime<=0&&to.path==="/sql/exe36")
                ||(restime<=0&&to.path==="/sql/exe37")
                ||(restime<=0&&to.path==="/sql/exe38")
                ||(restime<=0&&to.path==="/sql/exe39")
                ||(restime<=0&&to.path==="/sql/exe40")
                ||(restime<=0&&to.path==="/sql/exe45")
                ||(restime<=0&&to.path==="/sql/exe46")
                ||(restime<=0&&to.path==="/sql/exe47")
                ||(restime<=0&&to.path==="/sql/exe48")
                ||(restime<=0&&to.path==="/sql/exe49")
                ||(restime<=0&&to.path==="/sql/exe50")
                ||(restime<=0&&to.path==="/sql/exe51")
                ||(restime<=0&&to.path==="/sql/exe52")
                )
            {
                // 跳去会员页
                next("/vip")
            }else{
                next()
            }
        }
    }
})

export default router