<template>
  <div class="frame" id="dialogstyle1">
    <el-container class="frame-container" id="dialogstyle2">
      <el-header class="header">
        <ul class="ul-link">
          <li style="list-style:none;width:120px;margin-right:40px">
            <a href="/" class="brand">
              <img src="../../src/dathonicon/blackicon.png" style="height:26px;width:120px;"/>
            </a>
          </li>
          <li style="list-style:none;">
            <a href="https://mp.weixin.qq.com/s/t6HjNyGVKsG8kU77r4r6Sg" class="header-a" target="_blank">
            <el-badge value="hot" class="item">
                课程
            </el-badge>
            </a>
          </li>
          <li style="list-style:none;">
            <a href="/#/vip" class="header-a">会员</a>
          </li>
          <li style="list-style:none;">
            <div href="/" class="header-a" style="">
            <el-dropdown>
              <span class="el-dropdown-link" style="color:#18222a !important">
                题库<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button type="text" @click="gosql">SQL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
                  </el-dropdown-item>
                <el-dropdown-item>Python（尚未完成）</el-dropdown-item>
                <el-dropdown-item>统计学（尚未完成）</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            </div>
          </li>
          <li style="list-style:none;">
            <a href="/#/team" class="header-a">团队</a>
          </li>
          <li style="list-style:none;">
            <a href="/#/dashboard" class="header-a">行情</a>
          </li>
        </ul>

        <span style="flex:1;caret-color: transparent;"></span>

        <div class="header-content" v-if="token===null">
          <el-link  type="primary" class='zhuce' href="#/login">微信登录</el-link>
          <el-link  type="primary" class='denglu' href="#/zhlogin">账号登录</el-link>
        </div>
        <div v-else class="header-content">
          <el-dropdown @command="onLogout" class="touxiang">
            <span class="el-dropdown-link">
              <el-avatar :src=this.touxiang></el-avatar>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >{{username}}</el-dropdown-item>
              <el-dropdown-item command=1>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown @command="onLogout" class="vip" v-if="restime>0">
            <span class="el-dropdown-link" >
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='xiaoni'"></i>
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='139746406@qq.com'"></i>
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='644721169@qq.com'"></i>
                  <i class="iconfont icon-vip" style="color:red;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >您Vip将在{{restime}}天后过期</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>

      <el-container>
        <el-container>
          <el-main class="main">
            <el-row>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <!-- <router-></router-view> -->
                  <el-tabs type="border-card">
                    <el-tab-pane label="题目描述">
                      <div class="exercise_body" id="exercise_body">
                        <router-view></router-view>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="参考答案">
                      <div  style="overflow: auto;" >
                        <div v-if="this.token===null">
                          <el-empty description="需要登录才可以查看答案^ ^"></el-empty>
                        </div>
                        <div v-else-if="issubmit==0">
                          <el-empty description="至少需要自己做一遍并点击提交才可以查看答案哦^ ^ (ps:提交后还得刷新)"></el-empty>
                        </div>
                        <div  v-else class="exercise_body" id="exercise_body"> 
                          <exe_answer></exe_answer>
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="评论">
                      <div  style="overflow: auto;">
                        <div class="exercise_body" id="exercise_body">
                          <Comment></Comment>
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
                <div >
                  <el-drawer
                      title="全部题目"
                      :visible.sync="drawer2"
                      :direction="direction2"
                      custom-class="drawer2"
                      >
                      <el-divider content-position="left">
                          <span style="color:green; font-size:18px">简单题目</span>
                        </el-divider>
                      <div  style="padding:5px">
                        <router-link to="/sql/exe18" class="exe_link" >#1 查询男生、女生人数</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[17]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[17]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div  style="padding:5px">
                        <router-link to="/sql/exe2" class="exe_link" >#2 查询平均成绩大于60分的同学的学号和平均成绩</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[1]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[1]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe19" class="exe_link" >#3 查询名字中含有"风"字的学生信息</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[18]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[18]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe4" class="exe_link" >#4 查询姓“李”的老师的个数</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[3]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[3]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe23" class="exe_link" >#5 查询各学生的年龄</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[22]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[22]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe17" class="exe_link" >#6 查询每门课程被选修的学生数</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[16]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[16]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe7" class="exe_link" >#7 查询所有课程成绩小于60分的同学的学号、姓名</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[6]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[6]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe12" class="exe_link" >#8 查询学生的总成绩并进行倒序排序</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[11]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[11]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe9" class="exe_link" >#9  查询至少有一门课与“01”的同学所学相同的同学</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[8]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[8]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe20" class="exe_link" >#10 查询1990年出生的学生名单</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[19]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[19]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe21" class="exe_link" >#11 查询每门课程的平均成绩</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[20]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[20]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                        <el-divider content-position="left">
                          <span style="color:orange; font-size:18px">中等题目</span>
                        </el-divider>         
                      <div style="padding:5px">
                        <router-link to="/sql/exe8" class="exe_link" >#12  查询没有学全所有课的同学的学号、姓名</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[7]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[7]==0" type="warning" size="small">未完成</el-tag>
                      </div>             
                      <div style="padding:5px">
                        <router-link to="/sql/exe11" class="exe_link" >#13 查询各科成绩最高分、最低分和平均分</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[10]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[10]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe14" class="exe_link" >#14 统计各科成绩各分数段人数</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[13]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[13]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe15" class="exe_link" >#15 查询学生平均成绩及其名次</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[14]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[14]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe16" class="exe_link" >#16 查询各科成绩前三名的记录</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[15]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[15]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe6" class="exe_link" >#17 查询学过“张三”老师课程的同学的学号、姓名</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[5]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[5]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe1"  class="exe_link"  >#18 查询“01”课程比“02”课程成绩高的所有学生</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[0]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[0]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe3" class="exe_link" >#19 查询所有同学的学号、姓名、选课数、总成绩</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[2]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[2]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe10" class="exe_link" >#20 查询两门及其以上不及格课程的同学</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[9]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[9]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe13" class="exe_link" >#21 查询所有课程的成绩第2名到第3名的学生</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[12]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[12]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe22" class="exe_link" >#22 查询选修“张三”老师课程中成绩最高的同学</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[21]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[21]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe5" class="exe_link" >#23 查询学过“01”课程也学过“02”课程的同学</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[4]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[4]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe24" class="exe_link" >#24 查询所有同学里面最先过生日的同学</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[23]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[23]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <el-divider content-position="left">
                          <span style="color:blue; font-size:18px">常见套路</span>
                      </el-divider>
                      <div style="padding:5px">
                        <router-link to="/sql/exe42" class="exe_link" >#25 行转列</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[41]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[41]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe43" class="exe_link" >#26 列转行</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[42]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[42]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <router-link to="/sql/exe44" class="exe_link" >#27 计算分类TopN问题</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[43]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[43]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe45" class="exe_link" >#28 计算连续活跃/登录问题</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[44]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[44]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe46" class="exe_link" >#29 计算累计问题</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[45]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[45]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe47" class="exe_link" >#30 计算截尾均值</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[46]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[46]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe48" class="exe_link" >#31 计算留存率</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[47]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[47]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe49" class="exe_link" >#32 用户行为分析 - 1</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[48]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[48]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe50" class="exe_link" >#33 用户行为分析 - 2</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[49]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[49]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe51" class="exe_link" >#34 活动效果分析 - 1</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[50]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[50]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe52" class="exe_link" >#35 活动效果分析 - 2</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[51]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[51]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <el-divider content-position="left">
                          <span style="color:red; font-size:18px">大厂真题</span>
                      </el-divider>
                      <div style="padding:5px">
                        <router-link to="/sql/exe25" class="exe_link" >#36 计算2019年6月1日至6月20日每日DAU</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[24]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[24]==0" type="warning" size="small">未完成</el-tag>
                      </div>  
                      <div style="padding:5px">
                        <router-link to="/sql/exe26" class="exe_link" >#37 计算每天领取红包的用户数......</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[25]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[25]==0" type="warning" size="small">未完成</el-tag>
                      </div>  
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe27" class="exe_link" >#38 计算每个月领取过红包用户和未领取过红包......</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[26]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[26]==0" type="warning" size="small">未完成</el-tag>
                      </div>  
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe28" class="exe_link" >39 计算所有老用户领取的第一个红包金额</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[27]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[27]==0" type="warning" size="small">未完成</el-tag>
                      </div>  
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe29" class="exe_link" >#40 计算每日领取红包的新用户数、老用户数......</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[28]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[28]==0" type="warning" size="small">未完成</el-tag>
                      </div>  
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe30" class="exe_link" >#41 计算每个新用户领取红包的时间差</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[29]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[29]==0" type="warning" size="small">未完成</el-tag>
                      </div>  
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe31" class="exe_link" >#42 计算用户留存率......</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[30]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[30]==0" type="warning" size="small">未完成</el-tag>
                      </div>  
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe32" class="exe_link" >#43 计算每日DAU中，近3天连续登陆用户的占比......</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[31]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[31]==0" type="warning" size="small">未完成</el-tag>
                      </div>  
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe33" class="exe_link" >#44 计算领取红包用户领取金额的中位数......</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[32]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[32]==0" type="warning" size="small">未完成</el-tag>
                      </div>  
                      <div style="padding:5px">
                        <router-link to="/sql/exe34" class="exe_link" >#45 计算每日活跃用户数......</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[33]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[33]==0" type="warning" size="small">未完成</el-tag>
                      </div>  
                      <div style="padding:5px">
                        <router-link to="/sql/exe35" class="exe_link" >#46 计算每日登陆之后马上开始关卡游戏的用户数</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[34]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[34]==0" type="warning" size="small">未完成</el-tag>
                      </div>  
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe36" class="exe_link" >#47 计算家通关最大关卡的人数分布情况</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[35]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[35]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe37" class="exe_link" >#48 计算新增用户的关卡通关情况</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[36]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[36]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe38" class="exe_link" >#49 计算新增用户获取金币数的中位数</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[37]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[37]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe39" class="exe_link" >#50 计算次日流失用户分布情况</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[38]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[38]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe40" class="exe_link" >#51 计算次日流失用户分布情况</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[39]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[39]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                      <div style="padding:5px">
                        <i class="iconfont icon-vip" style="color:#ffe600;"></i>
                        <router-link to="/sql/exe41" class="exe_link" >#52 计算新增用户的1-7日留存率</router-link>
                        &nbsp;
                        <el-tag v-if="exeisdolist[40]==1" type="success" size="small">已完成</el-tag>
                        <el-tag v-if="exeisdolist[40]==0" type="warning" size="small">未完成</el-tag>
                      </div>
                    </el-drawer>
                </div>
              </el-col>

              <el-col :span="12">
                 <el-radio v-model="radio1" label="1" border class='mysqlversion' size="small">
                    <strong>Mysql 8.0</strong>
                 </el-radio>
                <div class="sql">
                  <textarea
                    ref="mycode"
                    class="codesql"
                  />
                  <!-- <iframe src="http://localhost:8081/" width="900" height="600" class="zzzz" scrolling="auto"></iframe> -->
                </div>
                <el-drawer
                  title="执行结果"
                  :destroy-on-close="true"
                  :visible.sync="drawer"
                  :direction="direction"
                  >
<!-- v-if='drawer' -->
                  <div v-if="msg===0" class="sqlerrormsg">
                    <p style='color:#ef4743'><strong>「执行出错」</strong></p>
                    <br/>
                    {{sqlmsg}}
                  </div>
                  <div v-else style="margin:20px">
                  <el-collapse v-model="activeNames" @change="handleChange">
                    <el-collapse-item title="自测结果" name="1">
                        <el-table :data="datalist"   stripe :header-cell-style="headClass" :cell-style="rowClass">
                                <el-table-column :label="head" v-for="(head, key) in dataheader" :key="head" :prop="key">
                                </el-table-column>
                        </el-table>
                      <!-- <div>{{sqlmsg}}</div> -->
                    </el-collapse-item>
                    <el-collapse-item title="预期结果" name="2">
                        <el-table :data="showdatalist"   stripe :header-cell-style="headClass" :cell-style="rowClass">
                                <el-table-column :label="head" v-for="(head, key) in showdataheader" :key="head" :prop="key">
                                </el-table-column>
                        </el-table>
                    </el-collapse-item>
                  </el-collapse>
                  </div>
                </el-drawer>
              </el-col>
            </el-row>
          </el-main>
          <el-footer class="footer">
            <div class="buts">
              <el-button type="primary" @click="drawer2 = true">
                <i class="iconfont icon-timu"></i>
                题目列表
                </el-button>
              <el-button type="primary" class="but1" @click="upnextexe">
                <i class="iconfont icon-shangyiti"></i>
                上一题
              </el-button>
              <el-button type="primary" class="but3" @click="downnextexe">
                下一题
                <i class="iconfont icon-xiayiti"></i>
              </el-button>
              <el-button type="primary"  @click="randomnextexe">
                <i class="iconfont icon-suiji"></i>
                随机一题
                </el-button>

              <el-button type="primary"   @click="touming(),answerjudge()" style="float:right">
                <i class="iconfont icon-tijiao"></i>
                提交
              </el-button>
              
              <el-button @click="sqljudge()" type="primary" :loading="loading" style="float:right">
                <i class="iconfont icon-runo24"></i>
                执行代码
              </el-button>

              <el-button @click="format()" type="primary" style="float:right">
                <i class="iconfont icon-geshihua"></i>
                SQL格式化
              </el-button>

            </div>
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
    <el-dialog
      :visible.sync="dialogVisible"
      v-if='dialogVisible'
      :close-on-click-modal=false
      @close="handleClose()"
      :destroy-on-close="true"
      width="30%">
      <div v-if="answer_judge===1">
        <el-result  icon="success" title="恭喜,您答对啦!">
          <template slot="extra">
            <el-button type="primary" size="medium" @click="downnextexe(),handleClose(),sqlclear()">前往下一题</el-button>
          </template>
        </el-result>
      </div>
      <div v-else>
        <el-result  icon="error" title="呀,答错了" subTitle="再接再厉^ ^">
          <template slot="extra">
            <el-button type="primary" size="medium" @click="closereload(),handleClose()">刷新网页</el-button>
            <el-button type="primary" size="medium" @click="buttonclose(),handleClose()">关闭</el-button>
          </template>
        </el-result>
      </div>

    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import "codemirror/theme/ambiance.css";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint.css";
import axios from "axios"
// import Qs from 'qs'

const CodeMirror = require("codemirror/lib/codemirror");
require("codemirror/addon/edit/matchbrackets");
require("codemirror/addon/selection/active-line");
require("codemirror/mode/sql/sql");
require("codemirror/addon/hint/show-hint");
require("codemirror/addon/hint/sql-hint");
// import sqlFormatter from 'sql-formatter'
// import exercises_1 from './exercises/junior_exercises_1'
import Comment from './Comment';
import exe_answer from '@/components/answers/exe_answer'
import sqlFormatter from 'sql-formatter'

export default {
  name: "",
  created(){
    this.token = localStorage.getItem("Authorization")
    this.username = localStorage.getItem('username')
    this.exeisdolistdatagain()
  },
  data() {
    return {
       drawer: false,
       drawer2:false,
       direction: 'btt',
       direction2:'ltr',
       sqlmsg:'',
       msg:'',
       activeNames: ['1'],
       radio1:'1',
       dialogVisible: false,
       answer_judge:'',
       token:'',
       username:'',
       touxiang:'',
       restime:'',
       datalist:[],
       dataheader:{},
       loading:false,
       showdatalist:[],
       showdataheader:{},
       issubmit:'',
       exeisdolist:'',
    };
  },
  components:{
    Comment,
    exe_answer
  },
  methods: {
    sqljudge(){

      this.loading = true;

      let sqlContent = "";
      sqlContent = this.editor.getValue();

      axios.post("https://dathonlearn.cn/sqljudge",
      {
        headers:{
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:sqlContent}
      ).then(res=>{
        this.sqlmsg=res.data.data
        this.msg=res.data.msg

        this.dataheader = {}
        for (var x in res.data.data[0]){
          this.dataheader[x] = x
        }

        this.datalist = res.data.data
        
      // 预计结果展示

        var title = ''
        title = document.getElementById("exercise_body").children[0].firstChild._prevClass

        axios.post("https://dathonlearn.cn/showanswerdatagain",
            {
              headers:{
              'Content-Type':'application/x-www-form-urlencoded'
            },
            data:title}
            ).then(res=>{

              this.showdataheader = {}
              for (var x in res.data.data[0]){
                this.showdataheader[x] = x
              }

              this.showdatalist = res.data.data

              this.drawer=true

              this.loading = false
            })
          })
    },
    handleChange(val) {
        console.log(val);
    },

    touming(){
      document.getElementById("dialogstyle2").style.opacity = 0.5
      document.getElementById("dialogstyle1").style.background='#000'
    },
    notouming(){
      document.getElementById("dialogstyle2").style.opacity = 1
      document.getElementById("dialogstyle1").style.background='#FFF'
    },
    handleClose() {
        document.getElementById("dialogstyle2").style.opacity = 1
        document.getElementById("dialogstyle1").style.background='#FFF'
      },
    
    onLogout(command){

      if (command==1){
        localStorage.removeItem("Authorization")
        localStorage.removeItem("headimg")
        localStorage.removeItem("username")
        localStorage.removeItem("dalay")
        localStorage.removeItem("_time")
        localStorage.removeItem("nickname")
        // remove所有存储
        this.$router.replace("/login")
      }
    },

    answerjudge(){
      var title = ''
      title = document.getElementById("exercise_body").children[0].firstChild._prevClass

      let sqlContent = "";
      sqlContent = this.editor.getValue();

      var username = localStorage.getItem('username')


      // var answer_username = ''

      if(username==null){
         username = "youke"
      }

      axios.get("https://dathonlearn.cn/answerjudge",
      {
        headers:{
        'Content-Type':'application/x-www-form-urlencoded'
      },
      params:{'title':title,'sql':sqlContent,'username':username}}
      ).then(res=>{

        this.answer_judge = res.data.data["judge"]
        this.dialogVisible = true
      })
    },

    getusermessage(){
      var username = {"username":localStorage.getItem('username')}

      axios.post("https://dathonlearn.cn/users/getusermessage/",
      {
        headers:{
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:username}
      ).then(res=>{

        this.touxiang = res.data.data.touxiang
        this.restime = res.data.data.restime

        // 与评论共享数据
        localStorage.setItem("nickname",res.data.data.nickname)
        localStorage.setItem("headimg",res.data.data.touxiang)
        localStorage.setItem("restime",res.data.data.restime)
      })
    },

    headClass(){
      return 'text-align: center;background:#eef1f6;'
    },
    rowClass () {
              return 'text-align: center;'
      },

    exeisdolistdatagain(){
      var username = {"username":localStorage.getItem('username')}

      if(username['username']!==null){
        axios.post("https://dathonlearn.cn/exeisdolistdatagain",
        {
          headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        data:username}
        ).then(res=>{

          this.exeisdolist = res.data.data

        })
      }
    },

    exeissubmit(exetitle){
      var data = {"username":localStorage.getItem('username'),"exetitle":exetitle}

      axios.post("https://dathonlearn.cn/exeissubmit",
      {
        headers:{
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:data}
      ).then(res=>{
        this.issubmit = res.data.data
      })
    },

    downnextexe(){
      //关闭对话框 
      this.dialogVisible=false
      var title_dict = ''

      var username = localStorage.getItem('username')


      if(username==null){
        title_dict = {'exe18':1,'exe2':2,'exe19':3,'exe4':4,'exe23':5,'exe17':6,'exe7':7,'exe12':8,'exe9':9,'exe20':10,
                  'exe21':11}
      }else if(this.restime<=0){
         title_dict = {'exe18':1,'exe2':2,'exe19':3,'exe4':4,'exe23':5,'exe17':6,'exe7':7,'exe12':8,'exe9':9,'exe20':10,
                  'exe21':11,'exe8':12,'exe11':13,'exe14':14,'exe15':15,'exe16':16,'exe6':17,'exe1':18,'exe3':19,
                  'exe10':20,'exe13':21,'exe22':22,'exe5':23,'exe24':24,'exe42':25,'exe43':26,'exe44':27,'exe25':28,
                  'exe26':29,'exe34':30,'exe35':31}
      }else{
        title_dict = {'exe18':1,'exe2':2,'exe19':3,'exe4':4,'exe23':5,'exe17':6,'exe7':7,'exe12':8,'exe9':9,'exe20':10,
                          'exe21':11,'exe8':12,'exe11':13,'exe14':14,'exe15':15,'exe16':16,'exe6':17,'exe1':18,'exe3':19,
                          'exe10':20,'exe13':21,'exe22':22,'exe5':23,'exe24':24,'exe42':25,'exe43':26,'exe44':27,'exe45':28,
                          'exe46':29,'exe47':30,'exe48':31,'exe49':32,'exe50':33,'exe51':34,'exe52':35,'exe25':36,'exe26':37,
                          'exe27':38,'exe28':39,'exe29':40,'exe30':41,'exe31':42,'exe32':43,'exe33':44,'exe34':45,'exe35':46,
                          'exe36':47,'exe37':48,'exe38':49,'exe39':50,'exe40':51,'exe41':52}
                          }

      function findKey (value, compare = (a, b) => a === b) {
          return Object.keys(title_dict).find(k => compare(title_dict[k], value))
        }

      var title = document.getElementById("exercise_body").children[0].firstChild._prevClass

      var titlenum = title_dict[title]

      titlenum = titlenum+1


      if(titlenum<Object.keys(title_dict).length+1){
        var nextlink = '/sql/'+findKey(titlenum)
        this.$router.push(nextlink)
      }else{
        this.$message({
          message:'已经是最后一题啦',
          type: 'warning'}
          );
      }
    },

    upnextexe(){
      var title_dict = ''
      var username = localStorage.getItem('username') 

      if(username==null){
         title_dict = {'exe18':1,'exe2':2,'exe19':3,'exe4':4,'exe23':5,'exe17':6,'exe7':7,'exe12':8,'exe9':9,'exe20':10,
                  'exe21':11}
      }else if(this.restime<=0){
         title_dict = {'exe18':1,'exe2':2,'exe19':3,'exe4':4,'exe23':5,'exe17':6,'exe7':7,'exe12':8,'exe9':9,'exe20':10,
                  'exe21':11,'exe8':12,'exe11':13,'exe14':14,'exe15':15,'exe16':16,'exe6':17,'exe1':18,'exe3':19,
                  'exe10':20,'exe13':21,'exe22':22,'exe5':23,'exe24':24,'exe42':25,'exe43':26,'exe44':27,'exe25':28,
                  'exe26':29,'exe34':30,'exe35':31}
      }else{
        title_dict = {'exe18':1,'exe2':2,'exe19':3,'exe4':4,'exe23':5,'exe17':6,'exe7':7,'exe12':8,'exe9':9,'exe20':10,
                          'exe21':11,'exe8':12,'exe11':13,'exe14':14,'exe15':15,'exe16':16,'exe6':17,'exe1':18,'exe3':19,
                          'exe10':20,'exe13':21,'exe22':22,'exe5':23,'exe24':24,'exe42':25,'exe43':26,'exe44':27,'exe45':28,
                          'exe46':29,'exe47':30,'exe48':31,'exe49':32,'exe50':33,'exe51':34,'exe52':35,'exe25':36,'exe26':37,
                          'exe27':38,'exe28':39,'exe29':40,'exe30':41,'exe31':42,'exe32':43,'exe33':44,'exe34':45,'exe35':46,
                          'exe36':47,'exe37':48,'exe38':49,'exe39':50,'exe40':51,'exe41':52}
                          }
      function findKey (value, compare = (a, b) => a === b) {
          return Object.keys(title_dict).find(k => compare(title_dict[k], value))
        }

      var title = document.getElementById("exercise_body").children[0].firstChild._prevClass

      var titlenum = title_dict[title]

      titlenum = titlenum-1

      if(titlenum>0){
        var nextlink = '/sql/'+findKey(titlenum)
        this.$router.push(nextlink)
      }else{
        this.$message({
          message:'已经是第一题啦',
          type: 'warning'}
          );
      }
    },
    randomnextexe(){
      var title_dict = ''

      var titlenum = ''

      var username = localStorage.getItem('username')


      if(username==null){
        title_dict = {'exe18':1,'exe2':2,'exe19':3,'exe4':4,'exe23':5,'exe17':6,'exe7':7,'exe12':8,'exe9':9,'exe20':10,
                  'exe21':11}
        titlenum = Math.floor(Math.random()*11+1)
      }
      else if(this.restime<=0){
         title_dict = {'exe18':1,'exe2':2,'exe19':3,'exe4':4,'exe23':5,'exe17':6,'exe7':7,'exe12':8,'exe9':9,'exe20':10,
                  'exe21':11,'exe8':12,'exe11':13,'exe14':14,'exe15':15,'exe16':16,'exe6':17,'exe1':18,'exe3':19,
                  'exe10':20,'exe13':21,'exe22':22,'exe5':23,'exe24':24,'exe42':25,'exe43':26,'exe44':27,'exe25':28,
                  'exe26':29,'exe34':30,'exe35':31}
         titlenum = Math.floor(Math.random()*31+1)
      }
      else{
        title_dict = {'exe18':1,'exe2':2,'exe19':3,'exe4':4,'exe23':5,'exe17':6,'exe7':7,'exe12':8,'exe9':9,'exe20':10,
                          'exe21':11,'exe8':12,'exe11':13,'exe14':14,'exe15':15,'exe16':16,'exe6':17,'exe1':18,'exe3':19,
                          'exe10':20,'exe13':21,'exe22':22,'exe5':23,'exe24':24,'exe42':25,'exe43':26,'exe44':27,'exe45':28,
                          'exe46':29,'exe47':30,'exe48':31,'exe49':32,'exe50':33,'exe51':34,'exe52':35,'exe25':36,'exe26':37,
                          'exe27':38,'exe28':39,'exe29':40,'exe30':41,'exe31':42,'exe32':43,'exe33':44,'exe34':45,'exe35':46,
                          'exe36':47,'exe37':48,'exe38':49,'exe39':50,'exe40':51,'exe41':52}
        titlenum = Math.floor(Math.random()*52+1)
                          }


      function findKey (value, compare = (a, b) => a === b) {
          return Object.keys(title_dict).find(k => compare(title_dict[k], value))
        }
      
      var nextlink = '/sql/'+findKey(titlenum)
      this.$router.push(nextlink)
    },

    /* 代码格式化*/
    format() {
      /* 获取文本编辑器内容*/
      let sqlContent = ''
      sqlContent = this.editor.getValue()
      /* 将sql内容进行格式后放入编辑器中*/
      this.editor.setValue(sqlFormatter.format(sqlContent))
    },

    sqlclear(){
      this.editor.setValue('')
    },

    closereload(){
      this.dialogVisible=false
      location.reload()
    },
    buttonclose(){
      this.dialogVisible=false
    },

    gosql(){
      this.$router.push("/sql/exe18")
    }
  },
  mounted() {
    let mime = "text/x-mysql";
    // let theme = 'ambiance'//设置主题，不设置的会使用默认主题
    this.editor = CodeMirror.fromTextArea(this.$refs.mycode, {
      value: this.value,
      mode: mime, //选择对应代码编辑器的语言，我这边选的是数据库，根据个人情况自行设置即可
      indentWithTabs: true,
      smartIndent: true,
      lineNumbers: true,
      matchBrackets: true,
      cursorHeight: 1,
      lineWrapping: true,
      readOnly: this.readOnly,
      line: true,
      //theme: theme,
      // autofocus: true,
      extraKeys: { Ctrl: "autocomplete" }, //自定义快捷键
      gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
      hintOptions: {
        //自定义提示选项
        // 当匹配只有一项的时候是否自动补全
        completeSingle: false,
        tables: {
            sc: ['sid', 'cid', 'score'],
            course: ['cid', 'cname', 'tid'],
            student:['sid','sname','sage','ssex'],
            teacher:['tid','tname'],
            tmp_dau_based:['imp_date','qimei','is_new'],
            tmp_packet_based:['imp_date','qimei','report_time','add_money'],
            log:['uid','datesub','event','time','level','v1','v2']
        }
      },
    });
    //代码自动提示功能，记住使用cursorActivity事件不要使用change事件，这是一个坑，那样页面直接会卡死
    // inputRead
    this.editor.on("keypress", () => {
      this.editor.showHint();
    });
    this.getusermessage()

    var exetitlemounted = document.getElementById("exercise_body").children[0].firstChild._prevClass
    this.exeissubmit(exetitlemounted)
  },
  watch:{
    "$route.path":function(to,from){
      console.log(from)
      let exetitle = to.slice(5,to.length)
      this.exeissubmit(exetitle)
      
    }
  }
};


</script>

<style scoped lang='scss'>
.frame-container {
  // height: 100px;
  .header {
    height: 60px;
    padding-left:0px !important;
    background: #eaeff5;
    display: flex;
    border-bottom: 1px solid #c0c0c0;
    .brand {
      border-bottom: 1px solid #c0c0c0;
      height: 60px;
      width: 200px;
      margin-left: -20px;
      font-size: 20px;
      color: #003366;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header-a{
        height: 60px;
        width: 60px;
        font-size: 14px;
        color: #18222a !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }

  .header-content {
    height: 60px;
    display: flex;
    align-items: center;
    left: -10%;
    color: #003366;
    .signout {
      cursor: pointer;
    }
  }
  .aside {
    background-color: #545c64;
    .el-menu {
      .is-active {
        background-color: #434a50 !important;
      }
    }
  }
  .main {
    height:calc(100vh - 60px - 60px) ;
    background-color: #eaeff5;
    padding: 0px;
    // padding-bottom: 100vh;

    .mysqlversion{
      margin: 3px 0px 0px 10px;
    }
    .sql{
      margin: 10px;
      margin-top: 5px;}
  }
  .footer {
    height: 60px;
    background-color: #eaeff5;
    border: 1px solid #c0c0c0;
    .buts {
      margin-top: 10px;
      .but1 {
        margin-left: 100px;
      }
    }
  }
}
</style>

<style scoped>
/* scoped 局部生效 */
.aside >>> .el-menu {
  border-right: none;
}
.el-header {
  border-color: black;
}

.el-drawer__header >>> el-drawer.ltr{
  color:white
}


</style>

<style>
.v-modal{
  opacity:0 !important
}
.el-dialog__wrapper{
  margin-bottom: 60px !important;
}
.el-drawer{
  margin-left: 51.5% !important;
  box-shadow:none !important;
  border-top: 1px solid #c0c0c0 !important;

}
.el-drawer.btt{
  width: 49% !important;
  height: 320px !important;
  overflow:auto !important;
  transform: none !important;
  margin-bottom: 60px !important;
  margin-right: 60px !important;
  left:-1% !important;
}


.el-drawer__header{
  margin: 0px !important;
  margin-bottom: 10px !important;
  font-weight: bold !important;
  color: #000000 !important;
}
/* .CodeMirror{
  height: calc(100vh - 60px - 20px - 110px);

} */
.CodeMirror.cm-s-default.CodeMirror-wrap{
  height: calc(100vh - 60px - 20px - 110px);
}
.CodeMirror-scroll{
  margin-bottom: -20px !important;
  padding-bottom: 20px !important;
}
.CodeMirror-gutters{
  min-height: 740px !important;
  height:740px !important;
}
.CodeMirror-gutter.CodeMirror-linenumbers{
  min-height: 740px !important;
  height:740px !important;
}
.sqlerrormsg{
  margin: 20px;
  margin-top:10px;
  color:#ef4743;
  /* font-weight: bold; */
  background-color: #eaeff5;
}
.CodeMirror-gutters{
    background-color: #fff
}
.cm-s-default .cm-keyword{
  color: #0000ff
}
.cm-s-default .cm-number{
  color:#1d953f
}
.cm-s-default .cm-variable-2{
  color:#f173ac
}
.el-tabs__nav-scroll{
  background-color: #eaeff5;
}
.el-dialog{
  opacity:1 !important;
}
.ac-champion{
    left: -31px;
    position: absolute;
    top: -116px;
    width: 499px;
}
.el-tabs--border-card{
  border: none;
  box-shadow:none;
}
.exercise_body{
  height: calc(100vh - 150px - 60px);
  overflow: auto;
}
.el-drawer.ltr.drawer2{
  margin-left:0 !important;
  background-color:#696969 !important;
  opacity:0.9 !important;
}
.el-drawer.ltr.drawer2 .el-drawer__header{
  color:#fff !important;
}


.el-drawer.ltr.drawer2 .el-drawer__body{
  padding: 20px;
  color: #F8F8FF !important;
}

.frame-container .header .brand{
  color:#000000 !important;
}

.router-link-active{
    background-color:#1d953f !important;;
    font-size: larger !important;;
    color: aliceblue !important;

  }

a {
    text-decoration:none ; 
    color: #F8F8FF !important;
}

.zhuce{
  margin-right:20px;
  color:black !important;
}
.denglu{
  color:black !important;
}
.touxiang{
  margin-right:5px;
  position:unset !important;
  color:black !important;
}
.vip{
  margin-right:20px;
  position:unset !important;
  color:black !important;
}

.grid-content.bg-purple{
  height:calc(100vh - 60px - 20px - 110px) !important;
}
.el-tabs.el-tabs--top.el-tabs--border-card{
  height:calc(100vh - 60px - 20px - 71px) !important;
  box-shadow:none !important;
}

/* header css */
.ul-link{
  display: flex;
  flex-direction:row;
}
.header-link{
  display: flex;
}
.el-badge__content.is-fixed{
  transform:translateY(-60%) translateX(120%) !important;
}

</style>



