<template>
<div calss="selector">
  <div class='exe29' id='exe29'>
      <p style="padding:0px 0px 10px 0px" class="titlefont">40、计算2019年6月1日至6月10日，每日领取红包的新用户数、老用户数</p>
      <span  class="zwfont">
        难度 
        <el-tag type="danger" size="small">困难</el-tag>
      </span>
      <span class="zwfont" style="margin-left:20px">
        <el-button type="primary" icon="el-icon-share" size="mini" @click="sharelink()">分享本题</el-button>
      </span>
      <el-divider></el-divider>
  </div>
  <p class="titlefont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-idea" style="color:#409EFF"></i>
    题目描述:
  </p>
  <p class="titlefont" style="padding:0px 0px 10px 0px">
    计算2019年6月1日至6月10日，每日领取红包的新用户数、老用户数及其人均领取金额，人均领取次数，要考虑领取红包但当日未登录的情况。</p>
  <br/>

  <p class="titlefont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-ziduan" style="color:#409EFF"></i>
    字段要求及顺序
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    1.&nbsp;日期（imp_date）
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    2.&nbsp;是否新用户（is_new）
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    3.&nbsp;用户数
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    4.&nbsp;人均领取金额
  </p>
    <p class="zwfont" style="padding:0px 0px 10px 0px">
    5.&nbsp;人均领取次数
  </p>
  <br/>

  <p class="titlefont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-shuju" style="color:#409EFF"></i>
    示例数据:
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-tips" style="color:#409EFF"></i>
    1.本题不一定用到以下全部表.&nbsp;&nbsp;
    &nbsp;
    2.表名需注意大小写.&nbsp;&nbsp;
    &nbsp;3.示例数据仅为部分数据，非全部数据.
  </p>
  <br/>
  <p class="titlefont">
    <i class="iconfont icon-biaoge" style="color:#409EFF"></i>
     用户活跃模型表. &nbsp; 表名：tmp_dau_based 
  </p>
  <br/>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-tips" style="color:#409EFF"></i>
    字段解释：
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    1.&nbsp;imp_date&nbsp;&nbsp;日期&nbsp;&nbsp;int格式.
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    2.&nbsp;qimei&nbsp;&nbsp;用户唯一标识（相当于用户id）&nbsp;&nbsp;int格式&nbsp;&nbsp;无空值.
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    3.&nbsp;is_new&nbsp;&nbsp;新用户标识&nbsp;&nbsp;int格式.&nbsp;&nbsp;（1标识新用户&nbsp;&nbsp;0标识老用户）
  </p>
  <br/>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-tips" style="color:#409EFF"></i>
    备注：一个用户1天只出现1次，出现即表示当日登陆。
  </p>
  <br/>
  <el-table :data="daudataList"  v-loading="loading" stripe>
          <el-table-column :label="head" v-for="(head, key) in dauheader" :key="head" :prop="key">
          </el-table-column>
  </el-table>
  <br/>
  <br/>
  <p class="titlefont">
    <i class="iconfont icon-biaoge" style="color:#409EFF"></i>
     红包参与模型表. &nbsp; 表名：tmp_packet_based 
  </p>
    <br/>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-tips" style="color:#409EFF"></i>
    字段解释：
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    1.&nbsp;imp_date&nbsp;&nbsp;日期&nbsp;&nbsp;int格式.
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    2.&nbsp;qimei&nbsp;&nbsp;用户唯一标识（相当于用户id）&nbsp;&nbsp;int格式&nbsp;&nbsp;无空值.
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    3.&nbsp;report_time&nbsp;&nbsp;领取时间戳&nbsp;&nbsp;int格式.（1标识新用户&nbsp;&nbsp;0标识老用户）
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    4.&nbsp;add_money&nbsp;&nbsp;领取金额&nbsp;&nbsp;int格式.（单位为分&nbsp;&nbsp;无空值或0值）
  </p>
  <br/>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-tips" style="color:#409EFF"></i>
    备注：日志流水表，每一行为领取1次红包。无特殊说明，一般不考虑领取红包但当日未登录的情况。
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    （为什么会出现“已领取红包，但未登录的情况”，因为在现实的数据中，数据上报无法做到十分准确，这种其实是BUG数据。需要开发完善上报。）
  </p>
  <br/>
  <el-table :data="packetdataList"  v-loading="loading" stripe>
          <el-table-column :label="head" v-for="(head, key) in packetheader" :key="head" :prop="key">
          </el-table-column>
  </el-table>
  <br/>
</div>
</template>

<script type="text/ecmascript-6">

// import axios from "axios"

export default {
  name:"",
  data(){
    return {
      loading:false,
      dauheader:{imp_date:'imp_date',qimei:'qimei',is_new:'is_new',},
      daudataList:[{imp_date:'20190301',qimei:'1254',is_new:'1'},{imp_date:'20190301',qimei:'8276',is_new:'1'},
                  {imp_date:'20190302',qimei:'8276',is_new:'0'},{imp_date:'20190302',qimei:'1380',is_new:'1'},
                  {imp_date:'20190303',qimei:'8616',is_new:'1'},{imp_date:'20190303',qimei:'6643',is_new:'1'},
                  {imp_date:'20190304',qimei:'6643',is_new:'0'},{imp_date:'20190304',qimei:'5404',is_new:'1'}],

      packetheader:{imp_date:'imp_date',qimei:'qimei',report_time:'report_time',add_money:'add_money'},
      packetdataList:[{imp_date:'20190301',qimei:'8276',report_time:'1551416271',add_money:'5'},
                      {imp_date:'20190301',qimei:'2337',report_time:'1551422308',add_money:'234'},
                      {imp_date:'20190302',qimei:'559',report_time:'1551527677',add_money:'123'},
                      {imp_date:'20190302',qimei:'4726',report_time:'1551500983',add_money:'7'},
                      {imp_date:'20190303',qimei:'7704',report_time:'1551585571',add_money:'185'},
                      {imp_date:'20190303',qimei:'119',report_time:'1551603097',add_money:'224'},
                      {imp_date:'20190304',qimei:'4209',report_time:'1551673492',add_money:'101'},
                      {imp_date:'20190304',qimei:'3825',report_time:'1551705749',add_money:'169'},],
    }
  },
  components:{},
    methods: {
      sharelink(){
        var link = window.location.href;

        this.copyContent = link
        var input = document.createElement("input"); // 直接构建input
        input.value = this.copyContent; // 设置内容
        console.log(input.value);
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例

         this.$message({
          message: '已复制本题链接，欢迎分享!',
          type: 'success'
         })
      }
    // tablegain(table,header,dataList){

    //   // let table = "sc";

  //     axios.get("https://dathonlearn.cn/primarytablegain",
  //     {
  //       headers:{
  //       'Content-Type':'application/x-www-form-urlencoded'
  //     },
  //     params:table}
  //     ).then(res=>{
  //       var dataList_temp = []
  //       dataList_temp = res.data.data
  //       for ( var j in dataList_temp){
  //         dataList.push(dataList_temp[j])
  //       }
  //       for (var x in res.data.data[0]){
  //         header[x] = x
  //       }
  //     })
  //   },
  //   },
  // mounted() {
      // this.tablegain("sc",this.scheader,this.scdataList);
      // console.log('scheader',this.scheader)
      // console.log('scdataList',this.scdataList)
      // this.tablegain("course",this.courseheader,this.coursedataList);
      // this.tablegain("student",this.studentheader,this.studentdataList);
      // this.tablegain("teacher",this.teacherheader,this.teacherdataList);
      }
}
</script>

<style scoped lang='scss'>
 
.selector{ font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif; };

.titlefont{
  color:#303133;
  font-size:16px;
  font-weight:600;
}

.zwfont{
  color:#303133;
  font-size:14px;
}

</style>