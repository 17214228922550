<template>
<div calss="selector">
  <div class='exe44' id='exe44'>
      <p style="padding:0px 0px 10px 0px" class="titlefont">27、计算分类TopN问题</p>
      <span  class="zwfont">
        难度 
        <el-tag type="danger" size="small">中等</el-tag>
      </span>
      <span class="zwfont" style="margin-left:20px">
        <el-button type="primary" icon="el-icon-share" size="mini" @click="sharelink()">分享本题</el-button>
      </span>
      <el-divider></el-divider>
  </div>
  <p class="titlefont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-idea" style="color:#409EFF"></i>
    题目描述:
  </p>
  <p class="titlefont" style="padding:0px 0px 10px 0px">
    每个商品浏览次数top3的用户信息，输出商品id、用户id、浏览次数。
  </p>

  <br/>

  <p class="titlefont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-ziduan" style="color:#409EFF"></i>
    字段要求及顺序
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    1.&nbsp;商品id（product_id）
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    2.&nbsp;用户id（user_id）
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    3.&nbsp;浏览次数（cnt）
  </p>
  <br/>

  <p class="titlefont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-shuju" style="color:#409EFF"></i>
    示例数据:
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-tips" style="color:#409EFF"></i>
    1.表名需注意大小写.&nbsp;&nbsp;
    &nbsp;2.示例数据仅为部分数据，非全部数据.
  </p>
  <br/>
  <p class="titlefont">
    <i class="iconfont icon-biaoge" style="color:#409EFF"></i>
     商品浏览表表. &nbsp; 表名：product_view
  </p>
  <br/>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-tips" style="color:#409EFF"></i>
    字段解释：
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    1.&nbsp;user_id&nbsp;&nbsp;用户id&nbsp;&nbsp;str格式.
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    2.&nbsp;product_id&nbsp;&nbsp;产品id&nbsp;&nbsp;str格式.
  </p>
  <br/>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-tips" style="color:#409EFF"></i>
    备注：每个顾客浏览任何一个商品时都会产生一条浏览记录，浏览记录存储的表名为product_view，访客的用户id为user_id，浏览的商品名称是product_id。 
  </p>
  <br/>
  <el-table :data="bhdataList"  v-loading="loading" stripe>
          <el-table-column :label="head" v-for="(head, key) in bhheader" :key="head" :prop="key">
          </el-table-column>
  </el-table>
  <br/>
  <br/>
 
</div>
</template>

<script type="text/ecmascript-6">

// import axios from "axios"

export default {
  name:"",
  data(){
    return {
      loading:false,
      bhheader:{user_id:'user_id',product_id:'product_id'},
      bhdataList:[{user_id:'A0001',product_id:'product a'},
      {user_id:'A0002',product_id:'product b'},
      {user_id:'A0001',product_id:'product b'},
      {user_id:'A0001',product_id:'product b'},
      {user_id:'A0003',product_id:'product c'},
      {user_id:'A0004',product_id:'product b'},
      {user_id:'A0001',product_id:'product a'},
      {user_id:'A0002',product_id:'product c'},],
    }
  },
  components:{},
    methods: {
      sharelink(){
        var link = window.location.href;

        this.copyContent = link
        var input = document.createElement("input"); // 直接构建input
        input.value = this.copyContent; // 设置内容
        console.log(input.value);
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例

         this.$message({
          message: '已复制本题链接，欢迎分享!',
          type: 'success'
         })
      }
    // tablegain(table,header,dataList){

    //   // let table = "sc";

  //     axios.get("https://dathonlearn.cn/primarytablegain",
  //     {
  //       headers:{
  //       'Content-Type':'application/x-www-form-urlencoded'
  //     },
  //     params:table}
  //     ).then(res=>{
  //       var dataList_temp = []
  //       dataList_temp = res.data.data
  //       for ( var j in dataList_temp){
  //         dataList.push(dataList_temp[j])
  //       }
  //       for (var x in res.data.data[0]){
  //         header[x] = x
  //       }
  //     })
  //   },
  //   },
  // mounted() {
      // this.tablegain("sc",this.scheader,this.scdataList);
      // console.log('scheader',this.scheader)
      // console.log('scdataList',this.scdataList)
      // this.tablegain("course",this.courseheader,this.coursedataList);
      // this.tablegain("student",this.studentheader,this.studentdataList);
      // this.tablegain("teacher",this.teacherheader,this.teacherdataList);
      }
}
</script>

<style scoped lang='scss'>
 
.selector{ font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif; };

.titlefont{
  color:#303133;
  font-size:16px;
  font-weight:600;
}

.zwfont{
  color:#303133;
  font-size:14px;
}

</style>