<template>
<div calss="selector">
  <div class='exe37' id='exe37'>
      <p style="padding:0px 0px 10px 0px" class="titlefont">48、计算新增用户在三日内（包含安装当日）的前50关的关卡通关情况</p>
      <span  class="zwfont">
        难度 
        <el-tag type="danger" size="small">困难</el-tag>
      </span>
      <span class="zwfont" style="margin-left:20px">
        <el-button type="primary" icon="el-icon-share" size="mini" @click="sharelink()">分享本题</el-button>
      </span>
      <el-divider></el-divider>
  </div>
  <p class="titlefont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-idea" style="color:#409EFF"></i>
    题目描述:
  </p>
  <p class="titlefont" style="padding:0px 0px 10px 0px">
    计算2021年11月20日-11月25日的新增用户在三日内(包含安装当日）的前50关的关卡通关情况
  </p>
  <br/>

  <p class="titlefont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-ziduan" style="color:#409EFF"></i>
    字段要求及顺序
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    1.&nbsp;关卡（level）
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    2.&nbsp;每一关开始人数（event为levelStart）
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    3.&nbsp;每一关通关人数（event为levelEnd，参数v2为'1'时代表通关）
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    4.&nbsp;每一关关卡通过率[通关人数除以开始人数,用百分数表示（加%），精确到万分位]
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    5.&nbsp;每一关剩余用户比例[每一关的开始人数占第一关开始人数比例，用百分数表示（加%），精确到万分位]
  </p>
  <br/>

  <p class="titlefont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-shuju" style="color:#409EFF"></i>
    示例数据:
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-tips" style="color:#409EFF"></i>
    1.表名需注意大小写.&nbsp;&nbsp;
    &nbsp;2.示例数据仅为部分数据，非全部数据.
  </p>
  <br/>
  <p class="titlefont">
    <i class="iconfont icon-biaoge" style="color:#409EFF"></i>
     玩家行为表. &nbsp; 表名：log
  </p>
  <br/>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    <i class="iconfont icon-tips" style="color:#409EFF"></i>
    字段解释：
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    1.&nbsp;uid&nbsp;&nbsp;用户id&nbsp;&nbsp;str格式.
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    2.&nbsp;datesub&nbsp;&nbsp;记录日期&nbsp;&nbsp;date格式.
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    3.&nbsp;event&nbsp;&nbsp;记录玩家行为&nbsp;&nbsp;str格式.&nbsp;&nbsp;
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    4.&nbsp;time&nbsp;&nbsp;记录行为时间（时间戳）&nbsp;&nbsp;bigint格式.&nbsp;&nbsp;
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    5.&nbsp;level&nbsp;&nbsp;记录当前玩家行为发生的关卡&nbsp;&nbsp;int格式.&nbsp;&nbsp;
  </p>
  <p class="zwfont" style="padding:0px 0px 10px 0px">
    6.&nbsp;v1、v2&nbsp;&nbsp;通用字段，值随event改变而改变&nbsp;&nbsp;str格式.&nbsp;&nbsp;
  </p>
  <br/>
  <br/>
  <el-table :data="bhdataList"  v-loading="loading" stripe>
          <el-table-column :label="head" v-for="(head, key) in bhheader" :key="head" :prop="key">
          </el-table-column>
  </el-table>
  <br/>
  <br/>
 
</div>
</template>

<script type="text/ecmascript-6">

// import axios from "axios"

export default {
  name:"",
  data(){
    return {
      loading:false,
      bhheader:{uid:'uid',datesub:'datesub',event:'event',time:'time',level:'level',v1:'v1',v2:'v2'},
      bhdataList:[{uid:'20066446',datesub:'2021-11-20',event:'getCoin',time:'1637429452',level:'1',v1:'100',v2:'1'},
                   {uid:'20066446',datesub:'2021-11-20',event:'levelEnd',time:'1637429489',level:'2',v1:'23.920577',v2:'1'},
                   {uid:'20066446',datesub:'2021-11-20',event:'levelEnd',time:'1637429446',level:'1',v1:'21.912949',v2:'1'},
                   {uid:'20066446',datesub:'2021-11-20',event:'levelStart',time:'1637429466',level:'2',v1:'4',v2:''},
                   {uid:'20066446',datesub:'2021-11-20',event:'levelStart',time:'1637429424',level:'1',v1:'1',v2:''},
                   {uid:'20066446',datesub:'2021-11-20',event:'login',time:'1637429418',level:'1',v1:'',v2:''},
                   {uid:'20066446',datesub:'2021-11-20',event:'install',time:'1637429418',level:'1',v1:'samsung|SM-T500',v2:''},
                   {uid:'20066446',datesub:'2021-11-20',event:'background',time:'1637429490',level:'3',v1:'77.066706',v2:'2'},
                   {uid:'20066446',datesub:'2021-11-20',event:'session',time:'1637429490',level:'3',v1:'77',v2:'1'},
                   {uid:'20066446',datesub:'2021-11-20',event:'login',time:'1637827034',level:'3',v1:'',v2:''}],
    }
  },
  components:{},
    methods: {
      sharelink(){
        var link = window.location.href;

        this.copyContent = link
        var input = document.createElement("input"); // 直接构建input
        input.value = this.copyContent; // 设置内容
        console.log(input.value);
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例

         this.$message({
          message: '已复制本题链接，欢迎分享!',
          type: 'success'
         })
      }
    // tablegain(table,header,dataList){

    //   // let table = "sc";

  //     axios.get("https://dathonlearn.cn/primarytablegain",
  //     {
  //       headers:{
  //       'Content-Type':'application/x-www-form-urlencoded'
  //     },
  //     params:table}
  //     ).then(res=>{
  //       var dataList_temp = []
  //       dataList_temp = res.data.data
  //       for ( var j in dataList_temp){
  //         dataList.push(dataList_temp[j])
  //       }
  //       for (var x in res.data.data[0]){
  //         header[x] = x
  //       }
  //     })
  //   },
  //   },
  // mounted() {
      // this.tablegain("sc",this.scheader,this.scdataList);
      // console.log('scheader',this.scheader)
      // console.log('scdataList',this.scdataList)
      // this.tablegain("course",this.courseheader,this.coursedataList);
      // this.tablegain("student",this.studentheader,this.studentdataList);
      // this.tablegain("teacher",this.teacherheader,this.teacherdataList);
      }
}
</script>

<style scoped lang='scss'>
 
.selector{ font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif; };

.titlefont{
  color:#303133;
  font-size:16px;
  font-weight:600;
}

.zwfont{
  color:#303133;
  font-size:14px;
}

</style>