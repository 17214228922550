import { render, staticRenderFns } from "./junior_exercises_19.vue?vue&type=template&id=500be15b&scoped=true&"
import script from "./junior_exercises_19.vue?vue&type=script&lang=js&"
export * from "./junior_exercises_19.vue?vue&type=script&lang=js&"
import style0 from "./junior_exercises_19.vue?vue&type=style&index=0&id=500be15b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "500be15b",
  null
  
)

export default component.exports