<template>
  <div id="building" >
      <el-container >
      <el-header class="header">

        <ul class="ul-link">
          <li style="list-style:none;width:120px;margin-right:40px">
            <a href="/" class="brand">
              <img src="../../src/dathonicon/minicon.png" style="height:26px;width:120px;"/>
            </a>
          </li>
          <li style="list-style:none;">
            <a href="https://mp.weixin.qq.com/s/t6HjNyGVKsG8kU77r4r6Sg" class="header-a" target="_blank">
            <el-badge value="hot" class="item">
                课程
            </el-badge>
            </a>
          </li>
          <li style="list-style:none;">
            <a href="/#/vip" class="header-a">会员</a>
          </li>
          <li style="list-style:none;">
            <div href="/" class="header-a" style="">
            <el-dropdown>
              <span class="el-dropdown-link" style="color:#F8F8FF !important">
                题库<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button type="text" @click="gosql">SQL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
                  </el-dropdown-item>
                <el-dropdown-item>Python（尚未完成）</el-dropdown-item>
                <el-dropdown-item>统计学（尚未完成）</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            </div>
          </li>
          <li style="list-style:none;">
            <a href="/#/team" class="header-a">团队</a>
          </li>
          <li style="list-style:none;">
            <a href="/#/dashboard" class="header-a">行情</a>
          </li>
        </ul>

        <span style="flex:1"></span>

        <div class="header-content" v-if="token===null">
          <el-link  type="primary" class='zhuce' href="#/login">微信登录</el-link>
          <el-link  type="primary" class='denglu' href="#/zhlogin">账号登录</el-link>
          <!-- <a class='zhuce' >注册</a>
          <a href="#/login" class='denglu'>登录</a> -->
        </div>
        <div v-else class="header-content">
          <el-dropdown @command="onLogout" class="touxiang">
            <span class="el-dropdown-link">
              <el-avatar :src=this.touxiang></el-avatar>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >{{username}}</el-dropdown-item>
              <el-dropdown-item command=1>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-dropdown @command="onLogout" class="vip" v-if="restime>0">
            <span class="el-dropdown-link" >
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='xiaoni'"></i>
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='139746406@qq.com'"></i>
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='644721169@qq.com'"></i>
                  <i class="iconfont icon-vip" style="color:red;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >您Vip将在{{restime}}天后过期</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>
      </el-header>

      <el-main class='main'>
        <img src="../../src/dathonicon/maxicon.png" />
        <p class="summary">专为数据分析师打造的刷题平台</p>
        <el-button type="success" icon="el-icon-notebook-2" class="gobutton" @click="gosql">点击刷题</el-button>
      </el-main>

      <el-footer class="home-foot">
        <div>
          <i class="iconfont icon-fankui" style="font-size:14px"></i><a href="https://support.qq.com/products/377168/" target="_blank" style="font-size:14px">&nbsp;产品反馈</a>
          &nbsp;&nbsp;&nbsp;&nbsp;

          <i class="iconfont icon-weixin" style="font-size:14px"></i>
          <el-tooltip placement="top">
            <div slot="content">
              <img src="../assets/picture/authorewm.png" style="height:200px;width:200px"/>
            </div>
            <span style="font-size:14px">&nbsp;联系作者</span>
          </el-tooltip>

          &nbsp;&nbsp;&nbsp;&nbsp;
          <i class="iconfont icon-tips" style="font-size:14px"></i>
            <el-tooltip placement="top">
            <div slot="content">
              <span>本站部分题目来源于网络，仅供学习参考，如有侵权，非常抱歉，请立即联系作者删除</span>
            </div>
            <span style="font-size:14px">&nbsp;免责声明</span>
          </el-tooltip>
        </div>
        <div style="margin-top:5px;">
          <a href="https://beian.miit.gov.cn/#/Integrated/index" style="font-size:14px;" target="_blank">粤ICP备2022004129号</a>
        </div>
      </el-footer>
    </el-container>
  </div>


</template>

<script>

import axios from "axios"

export default {
  name:"",
  created(){
    this.token = localStorage.getItem("Authorization")
    this.username = localStorage.getItem('username')
  },
  data(){
    return{
      token:"",
      username:"",
      touxiang:"",
      restime:"",
    }
  },
  methods:{
    onLogout(command){

      if (command==1){
        localStorage.removeItem("Authorization")
        localStorage.removeItem("headimg")
        localStorage.removeItem("username")
        localStorage.removeItem("dalay")
        localStorage.removeItem("_time")
        localStorage.removeItem("nickname")
        // remove所有存储
        this.$router.replace("/login")
      }
    },
    getusermessage(){
      var username = {"username":localStorage.getItem('username')}

      axios.post("https://dathonlearn.cn/users/getusermessage/",
      {
        headers:{
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:username}
      ).then(res=>{
        this.touxiang = res.data.data.touxiang
        this.restime = res.data.data.restime

        // 与评论共享数据
        localStorage.setItem("nickname",res.data.data.nickname)
        localStorage.setItem("headimg",res.data.data.touxiang)
        localStorage.setItem("restime",res.data.data.restime)
      })
    },
    gosql(){
      this.$router.push("/sql/exe18")
    }
  },
  mounted(){
    this.getusermessage()
  }
}
</script>


<style scoped lang='scss'>

  .header {
    height: 60px;
    display: flex;
    .brand {
      height: 60px;
      width: 200px;
      // margin-left: -20px;
      font-size: 20px;
      color: #003366;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header-a{
        height: 60px;
        width: 60px;
        font-size: 14px;
        color: #F8F8FF  !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }

</style>

<style scoped>

#building{
background:url("../assets/picture/homemin.jpg");
width:100%;	
height:100%;		
position:fixed;
opacity : 0.9;
background-color: '#000';
background-size:100% 100%;}



.header-content {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    color: #003366;
  }

.zhuce{
  margin-right:20px;
  color:#fff !important;
}

.denglu{
  color:#fff !important;
}

.main{
  text-align: center;
  position:absolute;
  top:35%;
  margin:0 auto;
  box-sizing:border-box;
  width:100%
}

.summary{
  color: #fff;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title{
  color:#fff;
  font-size: 40px;
}

.gobutton{
  font-size:15px
}

/* header css */
.ul-link{
  display: flex;
  flex-direction:row;
}
.header-link{
  display: flex;
}
.el-badge__content.is-fixed{
  transform:translateY(-60%) translateX(120%) !important;
}
.home-foot{
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  color:#fff;
  text-align: center;
  
}
</style>