<template>
  <div  >
      <el-container >
      <el-header class="header">
        <ul class="ul-link">
          <li style="list-style:none;width:120px;margin-right:40px">
            <a href="/" class="brand">
              <img src="../../src/dathonicon/blackicon.png" style="height:26px;width:120px;"/>
            </a>
          </li>
          <li style="list-style:none;">
            <a href="https://mp.weixin.qq.com/s/t6HjNyGVKsG8kU77r4r6Sg" class="header-a" target="_blank">
            <el-badge value="hot" class="item">
                课程
            </el-badge>
            </a>
          </li>
          <li style="list-style:none;">
            <a href="/#/vip" class="header-a">会员</a>
          </li>
          <li style="list-style:none;">
            <div href="/" class="header-a" style="">
            <el-dropdown>
              <span class="el-dropdown-link" style="color:#18222a !important">
                题库<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button type="text" @click="gosql">SQL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
                  </el-dropdown-item>
                <el-dropdown-item>Python（尚未完成）</el-dropdown-item>
                <el-dropdown-item>统计学（尚未完成）</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            </div>
          </li>
          <li style="list-style:none;">
            <a href="/#/team" class="header-a">团队</a>
          </li>
          <li style="list-style:none;">
            <a href="/#/dashboard" class="header-a">行情</a>
          </li>
        </ul>

        <span style="flex:1"></span>

        <div class="header-content" v-if="token===null">
          <el-link  type="primary" class='zhuce' href="#/login">微信登录</el-link>
          <el-link  type="primary" class='denglu' href="#/zhlogin">账号登录</el-link>
          <!-- <a class='zhuce' >注册</a>
          <a href="#/login" class='denglu'>登录</a> -->
        </div>
        <div v-else class="header-content">
          <el-dropdown @command="onLogout" class="touxiang">
            <span class="el-dropdown-link">
              <el-avatar :src=this.touxiang></el-avatar>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >{{username}}</el-dropdown-item>
              <el-dropdown-item command=1>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>


          <el-dropdown @command="onLogout" class="vip" v-if="restime>0">
            <span class="el-dropdown-link" >
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='xiaoni'"></i>
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='139746406@qq.com'"></i>
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='644721169@qq.com'"></i>
                  <i class="iconfont icon-vip" style="color:red;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >您Vip将在{{restime}}天后过期</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>
      </el-header>

        <el-main class='main'>
            <div class="vip-main">
                <div style="text-align:center;margin: 0 auto height:40px;">
                  <img src="../../src/dathonicon/vipicon.png" >
                </div>
                <div class="vip-title">
                    <h3 style="text-align: center;margin-top:10px">直达大厂真实业务题&nbsp;享受高质量答疑服务&nbsp;无惧大厂数据分析面试</h3>
                </div>
                <div class="vip-body">
                    <el-row :gutter="20">
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <el-button type="text"  size="mini" @click="goclass">&nbsp;&nbsp;Dathon学员免费</el-button>
                            <p style="margin-top:2%;text-align: center;color:rgba(33,40,53,.75)">&nbsp;99999&nbsp;天</p>
                            <p style="color:#faa755;text-align: center;font-size:45px;">￥259</p>
                            <p style="text-align: center;color:rgba(33,40,53,.75);font-size:14px">&nbsp;&nbsp;简直不用钱^ ^</p>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <el-button type="text"  size="mini" @click="goclass">&nbsp;&nbsp;Dathon学员免费</el-button>
                            <p style="margin-top:2%;text-align: center;color:rgba(33,40,53,.75)">&nbsp;365&nbsp;天</p>
                            <p style="color:#faa755;text-align: center;font-size:45px;">￥99</p>
                            <p style="text-align: center;color:rgba(33,40,53,.75);font-size:14px">&nbsp;&nbsp;仅￥0.27/天</p>
                        </div>
                    </el-col>
                 
                    <el-col :span="8">
                        <div class="grid-content bg-purple">
                            <el-button type="text"  size="mini" @click="goclass">&nbsp;&nbsp;Dathon学员免费</el-button>
                            <p style="margin-top:2%;text-align: center;color:rgba(33,40,53,.75)">&nbsp;90&nbsp;天</p>
                            <p style="color:#faa755;text-align: center;font-size:45px;">￥45</p>
                            <p style="text-align: center;color:rgba(33,40,53,.75);font-size:14px">&nbsp;&nbsp;仅￥0.5/天</p>
                        </div>
                    </el-col>
                    </el-row>

                    <el-row>
                         <el-col :span="24">
                             <div class="vip-table">
                                <el-table
                                :data="tableData"
                                style="width: 100%"
                                :header-cell-style="headClass" :cell-style="rowClass">
                                    <el-table-column
                                        label="答疑权益"
                                        >
                                        <template slot-scope="scope">
                                            <i class="iconfont icon-huiyuanquanyi" style="color:#ffc20e;"></i>
                                            <span style="margin-left: 10px">{{ scope.row.vip }}</span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        label="99卡"
                                        >
                                        <template slot-scope="scope">
                                            <el-tag
                                            :type="scope.row.always == 'yes' ? 'success' : 'info'"
                                            effect="dark"
                                            disable-transitions>{{scope.row.always}}</el-tag>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                        label="年卡">
                                        <template slot-scope="scope">
                                            <el-tag
                                            :type="scope.row.year == 'yes' ? 'success' : 'info'"
                                            effect="dark"
                                            disable-transitions>{{scope.row.year}}</el-tag>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                          
                                        label="季卡">
                                        <template slot-scope="scope">
                                            <el-tag
                                            :type="scope.row.season == 'yes' ? 'success' : 'info'"
                                            effect="dark"
                                            disable-transitions>{{scope.row.season}}</el-tag>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                  
                                        label="普通">
                                        <template slot-scope="scope">
                                            <el-tag
                                            :type="scope.row.normal == 'yes' ? 'success' : 'info'"
                                            effect="dark"
                                            disable-transitions>{{scope.row.normal}}</el-tag>
                                        </template>
                                    </el-table-column>

                                </el-table>
                             </div>
                         </el-col>
                    </el-row>

                    <el-row :gutter="10">
                      <el-col :span="6">
                          <div class="vip-foot">
                            <img src="../../src/vip_img/always.png" />
                          </div>
                      </el-col>
                      <el-col :span="6">
                          <div class="vip-foot">
                              <img src="../../src/vip_img/year.png" />
                          </div>
                      </el-col>
                      <el-col :span="6">
                          <div class="vip-foot">
                            <img src="../../src/vip_img/season.png" />
                          </div>
                      </el-col>
                      <el-col :span="6">
                          <div class="vip-foot">
                            <img src="https://s4.ax1x.com/2022/01/23/74iAN4.jpg" />
                          </div>
                      </el-col> 

                    </el-row>
                </div>
            </div>
        </el-main>

    </el-container>
  </div>


</template>

<script>

import axios from "axios"

export default {
  name:"",
  created(){
    this.token = localStorage.getItem("Authorization")
    this.username = localStorage.getItem('username')
  },
  data(){
    return{
      token:"",
      username:"",
      touxiang:"",
      restime:"",
      tableData: [{
            vip: '真实业务题',
            always: 'yes',
            year: 'yes',
            season:'yes',
            normal:'no'
          }, {
            vip: '微信答疑群',
            always: 'yes',
            year: 'yes',
            season:'yes',
            normal:'no'
          }, {
            vip: '直播答疑',
            always: 'yes',
            year: 'yes',
            season:'yes',
            normal:'no'
          }, {
            vip: '简历修改',
            always: 'yes',
            year: 'no',
            season:'no',
            normal:'no',
          },{
            vip: '1V1咨询',
            always: 'yes',
            year: 'no',
            season:'no',
            normal:'no'
          },
          ]
    }
  },
  methods:{
    onLogout(command){

      if (command==1){
        localStorage.removeItem("Authorization")
        localStorage.removeItem("headimg")
        localStorage.removeItem("username")
        localStorage.removeItem("dalay")
        localStorage.removeItem("_time")
        localStorage.removeItem("nickname")
        // remove所有存储
        this.$router.replace("/login")
      }
    },
    getusermessage(){
      var username = {"username":localStorage.getItem('username')}

      axios.post("https://dathonlearn.cn/users/getusermessage/",
      {
        headers:{
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:username}
      ).then(res=>{

        this.touxiang = res.data.data.touxiang
        this.restime = res.data.data.restime

        // 与评论共享数据
        localStorage.setItem("nickname",res.data.data.nickname)
        localStorage.setItem("headimg",res.data.data.touxiang)
        localStorage.setItem("restime",res.data.data.restime)
      })
    },
    headClass(){
            return 'text-align: center;background:#eef1f6;color:black'
        },
    rowClass () {
                return 'text-align: center;'
        },
    gosql(){
      this.$router.push("/sql/exe18")
    },
    goclass(){
      window.open("https://mp.weixin.qq.com/s/u_k0iaRjRKfgQX3voJ9lkQ") 
    }
  },
  mounted(){
    this.getusermessage()
  }
}
</script>


<style scoped lang='scss'>

  .header {
    height: 60px;
    padding-left:0px !important;
    background: #eaeff5;
    display: flex;
    border-bottom: 1px solid #c0c0c0;
    .brand {
      height: 60px;
      width: 200px;
      margin-left: -20px;
      font-size: 20px;
      color: #000000 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header-a{
        height: 60px;
        width: 60px;
        font-size: 14px;
        color: #18222a !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }

</style>

<style scoped>


.header-content {
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: #003366;
  }

.zhuce{
  margin-right:20px;
  color:black !important;
}
.denglu{
  color:black !important;
}

.main{
  background: #eaeff5;
  position:absolute;
  margin:0 auto;
  top:60px;
  width:100%;
  height: calc(100vh - 60px);
}


.vip-main{
    width:1000px;
    height: calc(100vh - 120px);
    margin: 0 auto;
}

/* .vip-body{
    width:1000px;
    height: calc(100vh - 120px);
    margin: 0 auto;
} */
.grid-content.bg-purple{
    /* width:200px; */
    margin-top:30px;
    height: 150px !important;
    border:3px solid #009ad6;
    border-radius:20px;
    background-color: #fff;
}
.vip-table{
    height: 340px;
    border:2px solid #009ad6;
    background-color: #fff;
}
.vip-foot{
    height: 260px !important;
    border:3px solid #009ad6;
    /* border-radius:20px; */
    background-color: #fff;
}

.vip-foot>img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}

/* header css */
.ul-link{
  display: flex;
  flex-direction:row;
}
.header-link{
  display: flex;
}
.el-badge__content.is-fixed{
  transform:translateY(-60%) translateX(120%) !important;
}
</style>