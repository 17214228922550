<template>
  <div  >
      <el-container >
      <el-header class="header">

        <ul class="ul-link">
          <li style="list-style:none;width:120px;margin-right:40px">
            <a href="/" class="brand">
              <img src="../../src/dathonicon/blackicon.png" style="height:26px;width:120px;"/>
            </a>
          </li>
          <li style="list-style:none;">
            <a href="https://mp.weixin.qq.com/s/u_k0iaRjRKfgQX3voJ9lkQ" class="header-a" target="_blank"> 
            <el-badge value="hot" class="item">
                课程
            </el-badge>
            </a>
          </li>
          <li style="list-style:none;">
            <a href="/#/vip" class="header-a">会员</a>
          </li>
          <li style="list-style:none;">
            <div href="/" class="header-a" style="">
            <el-dropdown>
              <span class="el-dropdown-link" style="color:#18222a !important">
                题库<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button type="text" @click="gosql">SQL&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
                  </el-dropdown-item>
                <el-dropdown-item>Python（尚未完成）</el-dropdown-item>
                <el-dropdown-item>统计学（尚未完成）</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            </div>
          </li>
          <li style="list-style:none;">
            <a href="/#/team" class="header-a">团队</a>
          </li>
          <li style="list-style:none;">
            <a href="/#/dashboard" class="header-a">行情</a>
          </li>
        </ul>



        <div class="header-content" v-if="token===null">
          <el-link  type="primary" class='zhuce' href="#/register">注册</el-link>
          <el-link  type="primary" class='denglu' href="#/login">登录</el-link>
          <!-- <a class='zhuce' >注册</a>
          <a href="#/login" class='denglu'>登录</a> -->
        </div>
        <div v-else class="header-content">
          <el-dropdown @command="onLogout" class="touxiang">
            <span class="el-dropdown-link">
              <el-avatar :src=this.touxiang></el-avatar>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >{{username}}</el-dropdown-item>
              <el-dropdown-item command=1>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>


          <el-dropdown @command="onLogout" class="vip" v-if="restime>0">
            <span class="el-dropdown-link" >
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='xiaoni'"></i>
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='139746406@qq.com'"></i>
                  <i class="iconfont icon-guzhu-rencaiceping" style="color:blue;font-size:150%"  v-if="username=='644721169@qq.com'"></i>
                  <i class="iconfont icon-vip" style="color:red;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >您Vip将在{{restime}}天后过期</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        </div>
      </el-header>

        <el-main >
              <div class="left-menu">
                <div class="item-menu">
                  <el-button type="primary" >默认按钮</el-button>
                </div >
                <div class="item-menu">
                  <el-button type="primary" @click="goclassmenu">课程菜单</el-button>
                </div>
                <div class="item-menu">
                  <el-button type="primary">默认按钮</el-button>
                </div>
                <div class="item-menu">
                  <el-button type="primary">默认按钮</el-button>
                </div>
              </div>
              <div class="classappy">
                <el-button type="success" round >默认按钮</el-button>
              </div>
              <img src="../assets/dathonclasspic/PC-1_01.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_02.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_03.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_04.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_05.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_06.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_07.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_08.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_09.jpg" id="dathonclassmenu" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_10.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_11.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_12.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_13.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC-1_14.jpg" style="display: block"/>
              <img src="../assets/dathonclasspic/PC2_01.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC2_02.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC2_03.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC2_04.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC2_05.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC2_06.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC2_07.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC2_08.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC2_09.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC2_10.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC2_11.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC2_12.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC3_01.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC3_02.jpg" style="display: block"/> 
              <img src="../assets/dathonclasspic/PC3_03.jpg" style="display: block"/> 
        </el-main>

    </el-container>
  </div>

</template>

<script>

import axios from "axios"

export default {
  name:"",
  created(){
    this.token = localStorage.getItem("Authorization")
    this.username = localStorage.getItem('username')
  },
  data(){
    return{
      token:"",
      username:"",
      touxiang:"",
      restime:"",
    }
  },
  methods:{
    onLogout(command){

      if (command==1){
        localStorage.removeItem("Authorization")
        localStorage.removeItem("headimg")
        localStorage.removeItem("username")
        localStorage.removeItem("dalay")
        localStorage.removeItem("_time")
        localStorage.removeItem("nickname")
        // remove所有存储
        this.$router.replace("/login")
      }
    },
    getusermessage(){
      var username = {"username":localStorage.getItem('username')}

      axios.post("https://dathonlearn.cn/users/getusermessage/",
      {
        headers:{
        'Content-Type':'application/x-www-form-urlencoded'
      },
      data:username}
      ).then(res=>{

        this.touxiang = res.data.data.touxiang
        this.restime = res.data.data.restime

        // 与评论共享数据
        localStorage.setItem("nickname",res.data.data.nickname)
        localStorage.setItem("headimg",res.data.data.touxiang)
        localStorage.setItem("restime",res.data.data.restime)
      })
    },
    gosql(){
      this.$router.push("/sql/exe18")
    },
    goclassmenu(){
      // var posi =  this.$refs.dathonclassmenu.offsetTop
      // document.documentElement.scrollTop=posi;
      document.getElementById("dathonclassmenu").scrollIntoView();
    }
  },
  mounted(){
    this.getusermessage()
  }
}
</script>


<style scoped lang='scss'>

  .header {
    height: 60px;
    padding-left:0px !important;
    background: #eaeff5;
    display: flex;
    border-bottom: 1px solid #c0c0c0;
    .brand {
      height: 60px;
      width: 200px;
      margin-left: -20px;
      font-size: 20px;
      color: #000000 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header-a{
        height: 60px;
        width: 60px;
        font-size: 14px;
        color: #18222a !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }

</style>

<style scoped>

.el-main{
  padding-top:5px;
}

.header-content {
    height: 60px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    color: #003366;
  }

.zhuce{
  left:95%;
  color:black !important;
}

.denglu{
  left:0% !important;
  color:black !important;
}

.main{
  background: #eaeff5;
  position:absolute;
  margin:0 auto;
  top:60px;
  width:100%;
  height: calc(100vh - 60px);
}

img{
  height: 70%;
  width:70%;
  margin:0 auto !important;
}

.left-menu{
    margin-top: -320px;
    display: block;
    position: fixed;
    width: 140px;
    right: 50%;
    top: 50%;
    z-index: 2;
    margin-right: 666px;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
    border-radius: 8px;
    /* display: none; */
}
.item-menu{
    font-size: 14px;
    color: #717a84;
    padding: 5px 0;
    line-height: 20px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.classappy{
    display: block;
    position: absolute;
    width: 140px;
    right: 60%;
    top: 48%;
    z-index: 2;
    /* margin-right: 500px; */
}
</style>